// 필요한 모듈 임포트
import axios from "axios";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useLotto } from "../../App";
import { reset } from "../../tools/zustandUpdate";

// 상수
import { SideMenu } from "../../constants/sideMenu";

// 이미지
import NewLogo from "../../img/common/LC_logo_ani_h.svg";
import CloseL from "../../img/icon/icon_close.svg";
import User from "../../tools/userInfo";
import Login from "./login";
import createAxiosInstance from "../../api/axiosConfig";

import ResultIcon from "../../img/icon/sack-dollar-solid.svg";
import MegaIcon from "../../img/icon/mega-icon.svg";
import PowerIcon from "../../img/icon/power-icon.svg";
// import MegaIcon from "../../img/common/USA_Mega_lottery_sm.png";
// import PowerIcon from "../../img/common/USA_Power_lottery_sm.png";
import StatIcon from "../../img/icon/chart-pie-solid.svg";
import MagaIcon from "../../img/icon/newspaper-solid.svg";
import NewsIcon from "../../img/icon/bullhorn-solid.svg";
import ContactIcon from "../../img/icon/headset-solid.svg";
import FaqIcon from "../../img/icon/circle-question-solid.svg";
import SynIcon from "../../img/icon/cubes-stacked-solid.svg";
import SideBarItem from "./sideBarItem";
import { useLocation } from "react-router-dom";

// type
interface SubItem {
  title: string;
  href: string;
}

export interface MenuItem {
  title: string;
  href?: string;
  img: string;
  sub?: SubItem[];
}

const forSideBar2: MenuItem[] = [
  {
    title: "USA Power Lottery",
    img: PowerIcon,
    sub: [
      { title: "Quick Picks", href: "/power?Q" },
      { title: "Choose Numbers", href: "/power?C" },
      { title: "Systems", href: "/power?S" },
      { title: "Favourites", href: "/power?F" },
    ],
  },
  {
    title: "USA Mega Lottery",
    img: MegaIcon,
    sub: [
      { title: "Quick Picks", href: "/mega?Q" },
      { title: "Choose Numbers", href: "/mega?C" },
      { title: "Systems", href: "/mega?S" },
      { title: "Favourites", href: "/mega?F" },
    ],
  },
  {
    title: "Syndicates",
    href: "/syndicates",
    img: SynIcon,
    // sub: [
    //   { title: "Syndicate list", href: "/syndicates" },
    //   { title: "Create Syndicate", href: "/syndicates/create" },
    // ],
  },
  {
    title: "Results",
    img: ResultIcon,
    sub: [
      { title: "USA Power Lottery", href: "/result2?tab=power" },
      { title: "USA Mega Lottery", href: "/result2?tab=mega" },
    ],
  },
  {
    title: "Statistics",
    img: StatIcon,
    sub: [
      { title: "Last Appearances", href: "/stat?type=power&tab=lastdrawn" },
      { title: "Frequency Numbers", href: "/stat?type=power&tab=frequency" },
      { title: "Odd and Even Numbers", href: "/stat?type=power&tab=oddeven" },
      {
        title: "Consecutive Numbers",
        href: "/stat?type=power&tab=consecutive",
      },
      { title: "High and Low Numbers", href: "/stat?type=power&tab=highlow" },
      { title: "Lucky Number Patterns", href: "/stat?type=power&tab=pattern" },
    ],
  },
  {
    title: "Magazine",
    img: MagaIcon,
    sub: [
      { title: "Home", href: "/magazine" },
      { title: "Real Estate", href: "/magazine?tab=estate" },
      { title: "Watches", href: "/magazine?tab=watch" },
      { title: "Yachts", href: "/magazine?tab=yacht" },
      { title: "Cars", href: "/magazine?tab=car" },
    ],
  },
  { title: "News", href: "/news", img: NewsIcon },
  { title: "Contact Us", href: "/email", img: ContactIcon },
  { title: "FAQ", href: "/faq", img: FaqIcon },
];

const SideBar = () => {
  const [activeTab, setActiveTab] = useState<string | null>(null);
  const { navOpen, setNavOpen, open, setOpen } = useLotto();
  const { api, token, info, setToken, setTotalInfo } = User();

  const axiosInstance = createAxiosInstance(api, token);
  const locations = useLocation();

  useEffect(() => {
    const currentPath = window.location.pathname + window.location.search;
    setActiveTab(currentPath);
  }, [locations.search, locations.pathname]);

  /** Logout */
  const logoutAxios = () => {
    axiosInstance.post(`/logout`, {}).then(({ data }) => {
      if (data.message === "success") {
        Swal.fire({
          title: "You have logged out successfully",
          icon: "success",
          allowOutsideClick: false,
          timer: 2000,
          didClose: () => {
            setToken("");
            reset(setTotalInfo);
            location.href = "/";
          },
        });
      }
    });
  };

  return (
    <>
      <div className={`side-nav-wrap ${navOpen ? "active" : ""}`}>
        <div className={`side-nav ${navOpen ? "active" : ""}`}>
          <div className="sideHeader">
            <img
              className="logo"
              onClick={() => (window.location.href = "/")}
              src={NewLogo}
              alt="Lottery Cluster"
            />
            <img
              className="btnClose"
              onClick={() => setNavOpen((prev) => !prev)}
              src={CloseL}
              alt="Close"
            />
          </div>
          <div className="myInfoWrap">
            {/* 로그인 전 */}
            {token.length === 0 ? (
              <>
                <div className="btnSignup" onClick={() => setOpen(true)}>
                  Log in now!
                </div>
                <div className="textSignin">
                  Don't have an account? <b>Sign up</b>
                </div>
              </>
            ) : (
              <>
                <div className="myInfoHead">
                  <div>
                    {info.firstName} {info.lastName}
                  </div>
                  <div>${info.cash}</div>
                  <div>Account Balance</div>
                </div>
                <div className="myInfoFoot">
                  <div>My Winnings</div>
                  <div>${info.totalPrize}</div>
                </div>
              </>
            )}
          </div>
          {forSideBar2.map((it: MenuItem, idx: number) => (
            <SideBarItem key={idx} data={it} activeTab={activeTab} />
          ))}

          {token.length !== 0 && (
            <div
              className="btn style01 sm logout"
              onClick={() => logoutAxios()}
            >
              <span className="material-symbols-rounded">logout</span>
              Logout
            </div>
          )}
        </div>
        <div className="dim" onClick={() => setNavOpen((prev) => !prev)}></div>
      </div>
      <Login open={open} setOpen={setOpen}></Login>
    </>
  );
};

export default SideBar;
