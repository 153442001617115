// tools
import React, { useEffect, useRef, useState } from "react";
import { useSwipeable } from "react-swipeable";

import Slider from "react-slick";

// set
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface Styles {
  [key: string]: React.CSSProperties;
}

export const Shorts = () => {
  const [target, setTarget] = useState<number>(0);
  const [mute, setMute] = useState<boolean>(true); // State to manage mute

  const [videos, setVideos] = useState<string[]>([
    "https://www.youtube.com/shorts/Ti4neVnw29o",
    "https://www.youtube.com/embed/DK1Ds993z7M",
    "https://www.youtube.com/embed/Qqo0O0zVPYk",
    "https://www.youtube.com/embed/Rcx_iBfzKWg",
    "https://www.youtube.com/embed/yiiIAGnnnJ4",
    "https://www.youtube.com/embed/rBVNWQp9ayM",
  ]);

  const slideRef = useRef<Slider | null>(null);
  const wheelRef = useRef<HTMLDivElement | null>(null);

  const settings = {
    vertical: true,
    verticalSwiping: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (current: number, next: number) => setTarget(next),
  };

  const styles: Styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "inherit",
      width: "100%",
      height: "100%",
      padding: "30px 0px",
      position: "relative",
    },
    effectLayer: {
      position: "absolute",
      width: "100%",
      height: "100px",
      zIndex: "3",
      backgroundColor: "black",
      opacity: "50%",
    },

    sliderWrapper: {
      position: "relative",
      maxWidth: "600px",
      width: "100%",
    },
    videoBox: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    video: {
      objectFit: "cover",
      overflow: "hidden",
      borderRadius: "20px",
      border: "none",
    },
    muteButton: {
      position: "absolute",
      bottom: "30px",
      left: "20px",
      padding: "10px",
      backgroundColor: "rgba(255, 255, 255, 0.3)",
      color: "white",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      zIndex: 4,
    },
  };

  const handleSwipe = (deltaY: number) => {
    if (deltaY < 0) {
      slideRef.current?.slickPrev();
    } else if (deltaY > 0) {
      slideRef.current?.slickNext();
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedUp: () => handleSwipe(1),
    onSwipedDown: () => handleSwipe(-1),
    trackMouse: true,
  });

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      event.preventDefault();
      event.stopPropagation();

      if (event.deltaY < 0) {
        slideRef.current?.slickPrev();
      } else if (event.deltaY > 0) {
        slideRef.current?.slickNext();
      }
    };

    const effectDiv = wheelRef.current;

    if (effectDiv) {
      effectDiv.addEventListener("wheel", handleWheel as EventListener, {
        passive: false,
      });
    }

    return () => {
      if (effectDiv) {
        effectDiv.removeEventListener("wheel", handleWheel as EventListener);
      }
    };
  }, []);

  const toggleMute = () => {
    setMute(!mute);
  };

  return (
    <div style={styles.container}>
      <div style={styles.effectLayer} {...swipeHandlers} ref={wheelRef} />
      <div style={styles.sliderWrapper}>
        {/* <button style={styles.muteButton} onClick={toggleMute}>
          {mute ? "Unmute" : "Mute"}
        </button> */}
        <Slider {...settings} ref={slideRef}>
          {/* {videos.map((it, idx) => (
            <div key={idx} style={styles.videoBox}>
              <iframe
                id={`player-${idx}`}
                src={
                  target === idx
                    ? `${it}?enablejsapi=1&autoplay=1&loop=1&playlist=${
                        it.split("/")[4]
                      }&mute=${mute ? 1 : 0}`
                    : `${it}?enablejsapi=1`
                }
                width="100%"
                height="600"
                allowFullScreen
                allow="autoplay"
                style={styles.video}
              />
            </div>
          ))} */}
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/ECWY-NNZkgQ?autoplay=1&mute=1"
            // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allow="autoplay mute:1"
          />
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/vWJeOECeOdE?autoplay=1&mute=1"
            // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allow="autoplay mute:1"
          />{" "}
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/vWJeOECeOdE?autoplay=1&mute=1"
            // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allow="autoplay mute:1"
          />{" "}
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/vWJeOECeOdE?autoplay=1&mute=1"
            // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allow="autoplay mute:1"
          />{" "}
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/vWJeOECeOdE?autoplay=1&mute=1"
            // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allow="autoplay mute:1"
          />{" "}
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/vWJeOECeOdE?autoplay=1&mute=1"
            // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allow="autoplay mute:1"
          />{" "}
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/vWJeOECeOdE?autoplay=1&mute=1"
            // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allow="autoplay mute:1"
          />{" "}
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/vWJeOECeOdE?autoplay=1&mute=1"
            // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allow="autoplay mute:1"
          />
        </Slider>
      </div>
    </div>
  );
};

export default Shorts;
