// img

import EventImg03 from "../../img/main/Hero02_diet.png";
import EventImg04 from "../../img/main/Hero02_rm.png";

const Event = () => {
  return (
    <section className="event-area">
      <img src={EventImg04} alt="Hero-Img" />
    </section>
  );
};

export default Event;
