import React from "react";
import { NewsProps } from "../../pages/news";

interface NewsCardProps {
  data: NewsProps;
}

const NewsCard = ({ data }: NewsCardProps) => {
  return (
    <a
      href={data.url}
      target="_blank"
      rel="noopener noreferrer"
      className="item"
    >
      <div className="img-wrap">
        <img src={data.thumbnail} alt="News thumbnail" />
      </div>
      <div className="txt-wrap">
        <p className="tit">{data.subject}</p>
        <p className="site">Publisher: {data.source}</p>
        <p className="date">Published date: {data.publishedAt}</p>
      </div>
    </a>
  );
};

export default NewsCard;
