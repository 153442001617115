// tools
import { Dispatch, SetStateAction, useState } from "react";
import Swal from "sweetalert2";
import User from "../../tools/userInfo";
import NumberOnly from "../../tools/numberOnly";

// ui
import TextField from "@mui/material/TextField";
import createAxiosInstance from "../../api/axiosConfig";
interface ModalContent {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const Limit = ({ setOpen }: ModalContent) => {
  const { info, api, token } = User();
  const [limit, setLimit] = useState("");

  const axiosInstance = createAxiosInstance(api, token);

  const limitSet = (input: string) => {
    const number = Number(NumberOnly(input));

    if (number <= 10000) {
      setLimit(NumberOnly(input));
    } else {
      Swal.fire({
        title: "Error",
        text: "You cannot set weekly spend limit more than $10,000.",
        icon: "error",
        allowOutsideClick: false,
      });
      setOpen(false);
    }
  };

  const limitAxios = () => {
    axiosInstance
      .post(`/auth/spend/limit`, {
        amount: Number(limit),
      })
      .then(({ data }) => {
        Swal.fire({
          title: "Success",
          text: `Your Weekly Spending Limit has been set to $${limit}.`,
          icon: "success",
          allowOutsideClick: false,
          didClose: () => location.reload(),
        });
        setOpen(false);
      })
      .catch(({ response }) => {
        Swal.fire({
          title: "Error",
          text: response.data.message,
          icon: "error",
          allowOutsideClick: false,
        });
        setOpen(false);
      });
  };

  return (
    <div className="spend-limit">
      <div className="modal-tit-wrap">
        <h3 className="tit">Spend Limit</h3>
        <span
          className="btn material-symbols-outlined"
          onClick={() => setOpen(false)}
        >
          close
        </span>
      </div>
      <div className="modal-cont-wrap">
        {/* <p className="limit">
          Your current weekly spend limit is
          <span> ${info.weeklySpendLimit.toLocaleString("au")}</span>
        </p> */}
        <p className="subtit">
          The maximum weekly spending limit is $10,000.00.{" "}
        </p>
        <p className="subtxt">
          Set a Spend Limit to control your spending. Once reached, no further
          bets or purchases can be made until the next period.
        </p>
        <form>
          <TextField
            placeholder="10,000.00"
            value={limit}
            label="Spend Limit"
            onChange={({ target }) => limitSet(target.value)}
          />

          <p className="remain">
            Remaining : <span>$ 10,000.00</span>
          </p>
        </form>
        <div className="notice-area">
          <p>
            Once you reach your limit, our system will alert you and stop you
            from making a deposit or purchase. Your spend limit can be adjusted
            only once within a 24-hour period to ensure responsible gaming
            practices.
          </p>
        </div>
      </div>
      <div className="modal-bottom-wrap">
        <div className="btn-wrap">
          <div className="btn style01 sm" onClick={() => setOpen(false)}>
            Cancel
          </div>
          <div
            className="btn style01 sm white"
            style={{ padding: "12px 36px" }}
            onClick={() => limitAxios()}
          >
            Save
          </div>
        </div>
      </div>
    </div>
  );
};

export default Limit;
