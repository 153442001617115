// tools
import { ReactNode, useEffect } from "react";

// ui
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Dayjs } from "dayjs";
import Dialog from "@mui/material/Dialog";

interface CustModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose: (value: string) => void;
  children: ReactNode;
}

const CustDatePicker = ({
  onChange,
  target,
  readOnly,
}: {
  onChange: (event: Dayjs | null) => void;
  target: string[];
  readOnly: boolean;
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        readOnly={readOnly}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": { top: "-8px" },
        }}
        onChange={onChange}
        shouldDisableDate={(date) => {
          const converted = date.format("YYYY-MM-DD");
          const allow = !target.includes(converted);

          return allow;
        }}
      />
    </LocalizationProvider>
  );
};

const CustModal = ({ open, onClose, children }: CustModalProps) => {
  return (
    <Dialog
      onClose={onClose}
      open={open}
      keepMounted
      // disableScrollLock
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "720px",
          backgroundColor: "rgba(0,0,0, 0.01)",
          backdropFilter: "blur(24px)",
          border: "1px solid rgba(255,254,241, 0.1)",
          borderRadius: "16px",
        },
        "& .MuiBackdrop-root": {
          backdropFilter: "blur(4px)",
        },
      }}
    >
      <div className="cust-modal">{children}</div>
    </Dialog>
  );
};

export { CustDatePicker, CustModal };
