// tools
import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import User from "../tools/userInfo";

// ui
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import FloatingGame from "../components/common/FloatingGame";
import createAxiosInstance from "../api/axiosConfig";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";

// img
import Livechat from "../img/icon/livechat-icon.svg";
import Facebook from "../img/icon/facebook-icon.svg";
import Instagram from "../img/icon/instagram-icon.svg";

const EmailUs = () => {
  const [method, setMethod] = useState("email");
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [info, setInfo] = useState({
    name: "",
    email: "",
    phone: "",
    day: "",
    time: "",
    country: "",
    state: "",
    topic: "",
    content: "",
  });
  const { api } = User();

  const axiosInstance = createAxiosInstance(api, "");

  const handleData = (key: string, value: string) => {
    setInfo((prev) => ({ ...prev, [key]: value }));
  };

  const matches = useMediaQuery("(max-width:480px)");

  const emailAxios = () => {
    // Callback
    if (method === "callback") {
      const requiredFields: (keyof typeof info)[] =
        info.country === "AU"
          ? [
              "name",
              "email",
              "phone",
              "day",
              "time",
              "country",
              "state",
              "topic",
              "content",
            ]
          : ["name", "phone", "day", "time", "country", "topic", "content"];

      const emptyField = requiredFields.find((field) => !info[field]);

      if (!emptyField) {
        if (emailError) {
          return;
        }

        axiosInstance
          .post(`/support/contact/callback`, info)
          .then((_) => {
            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Email has been sent successfully. We will respond as soon as possible.",
              allowOutsideClick: false,
              didClose: () => (location.href = "/"),
            });
          })
          .catch(({ response }) => {
            Swal.fire({
              title: "Error",
              text: response.data.message,
              icon: "error",
              allowOutsideClick: false,
              didClose: () => window.location.reload(),
            });
          });
      } else {
        Swal.fire({
          title: "Error",
          text: `You haven't filled out a required field: ${emptyField}`,
          icon: "error",
          allowOutsideClick: false,
        });
      }
      // Email
    } else {
      const requiredFields: (keyof typeof info)[] = [
        "name",
        "email",
        "phone",
        "topic",
        "content",
      ];
      const emptyField = requiredFields.find((field) => !info[field]);

      if (!emptyField) {
        if (emailError) {
          return;
        }

        axiosInstance
          .post(`/support/contact/email`, info)
          .then((_) => {
            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Email has been sent successfully. We will respond as soon as possible.",
              allowOutsideClick: false,
              didClose: () => (location.href = "/"),
            });
          })
          .catch(({ response }) => {
            Swal.fire({
              title: "Error",
              text: response.data.message,
              icon: "error",
              allowOutsideClick: false,
              // didClose: () => window.location.reload(),
            });
          });
      } else {
        Swal.fire({
          title: "Error",
          text: `You haven't filled out a required field: ${emptyField}`,
          icon: "error",
          allowOutsideClick: false,
        });
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMethod((event.target as HTMLInputElement).value);
    setInfo((prev) => ({
      ...prev,
      day: "",
      time: "",
      country: "",
      state: "",
      topic: "",
      content: "",
    }));
  };

  useEffect(() => {
    if (info.email.length === 0) {
      setEmailError(false);
    }

    if (info.phone.length !== 0 && info.phone.length !== 10) {
      setPhoneError(true);
    } else {
      setPhoneError(false);
    }
  }, [info.email, info.phone]);

  return (
    <>
      <div className="sub email-us">
        <div className="email-us-wrap">
          <div className="sub-top-area">
            <h3 className="tit">Email Us or Request a Callback</h3>
            <p className="txt">
              Our team of lottery experts is prepared to provide a quick and
              thorough response to all your questions and concerns via email.
            </p>
          </div>

          <form>
            <div className="input-wrap">
              <TextField
                label="Name"
                sx={{ width: "calc(50% - 6px)" }}
                value={info.name}
                onChange={({ target }) => handleData("name", target.value)}
              />
            </div>
            <div className="input-wrap">
              <TextField
                label="Email"
                sx={{ width: "50%" }}
                value={info.email}
                onChange={({ target }) => {
                  handleData("email", target.value);
                  const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
                    target.value
                  );
                  setEmailError(!isValidEmail);
                }}
                error={emailError}
                helperText={
                  emailError ? "Please enter a valid email address." : ""
                }
              />
              <TextField
                label="Phone"
                sx={{ width: "50%" }}
                value={info.phone}
                onChange={({ target }) => {
                  const numericValue = target.value.replace(/[^0-9]/g, "");
                  handleData("phone", numericValue);
                }}
                error={phoneError}
                helperText={
                  phoneError ? "Please enter a valid phone Number." : ""
                }
                inputProps={{ maxLength: 10 }}
              />
            </div>

            <RadioGroup
              defaultValue="email"
              name="radio-buttons-group"
              onChange={handleChange}
            >
              <div className="input-wrap">
                <FormControlLabel
                  value="email"
                  control={<Radio />}
                  label="Email"
                  sx={{ width: "50%" }}
                />
                <FormControlLabel
                  value="callback"
                  control={<Radio />}
                  label="Request Callback"
                  sx={{ width: "50%" }}
                />
              </div>
            </RadioGroup>

            {method === "email" && (
              <>
                <div className="input-wrap">
                  <TextField
                    label="Topic"
                    fullWidth
                    value={info.topic}
                    onChange={({ target }) => handleData("topic", target.value)}
                  />
                </div>
                <TextField
                  label="Content"
                  fullWidth
                  multiline
                  rows={10}
                  defaultValue=""
                  onChange={({ target }) => handleData("content", target.value)}
                />
              </>
            )}

            {method === "callback" && (
              <>
                <div className="input-wrap">
                  <FormControl sx={{ width: "50%" }}>
                    <InputLabel id="call-day">Preferred Call Day</InputLabel>
                    <Select
                      labelId="call-day"
                      id=""
                      value={info.day}
                      label="Preferred Call Day"
                      onChange={({ target }) => handleData("day", target.value)}
                    >
                      <MenuItem value="MON">Monday</MenuItem>
                      <MenuItem value="TUE">Tuesday</MenuItem>
                      <MenuItem value="WED">Wednesday</MenuItem>
                      <MenuItem value="THU">Thursday</MenuItem>
                      <MenuItem value="FRI">Friday</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl sx={{ width: "50%" }}>
                    <InputLabel id="call-time">Select Time</InputLabel>
                    <Select
                      labelId="call-time"
                      id=""
                      value={info.time}
                      label="Select Time"
                      onChange={({ target }) =>
                        handleData("time", target.value)
                      }
                    >
                      <MenuItem value="09:00-13:00">09:00 - 13:00</MenuItem>
                      <MenuItem value="13:00-17:00">13:00 - 17:00</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div className="input-wrap">
                  <FormControl sx={{ width: "calc(50% - 6px)" }}>
                    <InputLabel id="country">Select a Country</InputLabel>
                    <Select
                      labelId="country"
                      id=""
                      value={info.country}
                      label="Select a Country"
                      onChange={({ target }) =>
                        handleData("country", target.value)
                      }
                    >
                      <MenuItem value="AU">Australia</MenuItem>
                      <MenuItem value="NZ">New Zealand</MenuItem>
                    </Select>
                  </FormControl>

                  {info.country === "AU" && (
                    <FormControl sx={{ width: "50%" }}>
                      <InputLabel id="state">Select a State</InputLabel>
                      <Select
                        labelId="state"
                        id=""
                        value={info.state}
                        label="Select a State"
                        onChange={({ target }) =>
                          handleData("state", target.value)
                        }
                      >
                        <MenuItem value="ACT">ACT</MenuItem>
                        <MenuItem value="NSW">NSW</MenuItem>
                        <MenuItem value="NT">NT</MenuItem>
                        <MenuItem value="QLD">QLD</MenuItem>
                        <MenuItem value="SA">SA</MenuItem>
                        <MenuItem value="VIC">VIC</MenuItem>
                        <MenuItem value="WA">WA</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </div>

                <div className="input-wrap">
                  <FormControl fullWidth>
                    <InputLabel id="call-reason">
                      Select Callback Reason
                    </InputLabel>
                    <Select
                      labelId="call-reason"
                      id=""
                      value={info.topic}
                      label="Select Callback Reason"
                      onChange={({ target }) =>
                        handleData("topic", target.value)
                      }
                    >
                      <MenuItem value="1">
                        Assistance with creating an account or resetting
                        passwords
                      </MenuItem>
                      <MenuItem value="2">
                        Instructions on how to play our lotteries
                      </MenuItem>
                      <MenuItem value="3">
                        Support for responsible gambling resources
                      </MenuItem>
                      <MenuItem value="4">
                        Report an issue with website or app
                      </MenuItem>
                      <MenuItem value="5">Reopen or close an account </MenuItem>
                      <MenuItem value="6">Payment enquiry </MenuItem>
                      <MenuItem value="7">Other </MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <TextField
                  label="Content"
                  fullWidth
                  multiline
                  rows={10}
                  value={info.content}
                  onChange={({ target }) => handleData("content", target.value)}
                />
              </>
            )}

            <div className="btn-wrap">
              <div
                className="btn style01 md white"
                onClick={() => emailAxios()}
              >
                Send
              </div>
            </div>
          </form>
        </div>

        {/* 우측 플로팅  */}
        <div className="right-side">
          <FloatingGame type={""} />
          <div className="contact-chat-wrap">
            <div className="item livechat">
              <img src={Livechat} alt="Live Chat" />
              <span>Live Chat</span>
            </div>
            <div className="item facebook">
              <img src={Facebook} alt="Facebook" />
              <span>Contact Us on Messenger</span>
            </div>
            <div className="item instagram">
              <img src={Instagram} alt="Instagram" />
              <span>Contact Us on Instagram</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailUs;
