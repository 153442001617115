// tools
import { useState, useEffect } from "react";
import User from "../../tools/userInfo";

// ui
import useMediaQuery from "@mui/material/useMediaQuery";
import Pagination from "@mui/material/Pagination";
import { CircularProgress, Skeleton } from "@mui/material";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import {
  DatePicker,
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// components
import NoData from "../common/noData";
import createAxiosInstance from "../../api/axiosConfig";
import { CustomDatePickerProps } from "./draws";

interface List {
  balance: number;
  cash: number;
  createdAt: string;
  description: string;
  idx: number;
  status: string;
  prize?: string;
  isPending?: boolean;
}

// date Picker
const CustomDay = (props: PickersDayProps<Dayjs>) => {
  const { day, ...other } = props;

  // 오늘 날짜 체크 (스타일 변경을 위해 남겨둠)
  const isToday = day.isSame(dayjs(), "day");

  return <PickersDay {...other} day={day} />;
};

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  label,
  value,
  onChange,
}) => {
  return (
    <DatePicker
      label={label}
      value={value}
      onChange={onChange}
      shouldDisableDate={() => false}
      slots={{
        day: (dayProps) => <CustomDay {...dayProps} />,
      }}
      slotProps={{
        textField: {
          error: false,
        },
      }}
    />
  );
};

const SelectTypeFilter = [
  { code: "cash", title: "Deposit" },
  { code: "prize", title: "Winning" },
  { code: "bonus", title: "Bonus" },
];

const History = () => {
  const { api, token } = User();
  const [page, setPage] = useState({
    max: 1,
    current: 1,
  });
  const [list, setList] = useState<List[]>([]);
  const [select, setSelect] = useState<string>("cash");
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState<Dayjs | null>(
    dayjs().subtract(30, "day")
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());

  const axiosInstance = createAxiosInstance(api, token);

  const listAxios = (page: number) => {
    axiosInstance
      .post(`/cash/usage/history`, {
        startDate: "",
        endDate: "",
        page,
      })
      .then(({ data }) => {
        const Data = data.data;

        setPage({
          max: Data.last_page,
          current: Data.current_page,
        });

        setLoading(false);

        setList(Data.data);
      });
  };

  const listAxios2 = (page: number) => {
    const sDate = startDate?.format("YYYY-MM-DD");
    const eDate = endDate?.format("YYYY-MM-DD");

    setLoading(true);
    axiosInstance
      .get(
        `funds/history?type=${select}&sDate=${sDate}&eDate=${eDate}&perPage=20&page=${page}`
      )
      .then(({ data }) => {
        const Data = data.data;

        setPage({
          max: Data.last_page,
          current: Data.current_page,
        });

        setList(Data.data);
      })
      .finally(() => setLoading(false));
  };

  const isFold = useMediaQuery("(max-width: 840px)");

  useEffect(() => {
    setList([]);
    setPage((prev) => ({ ...prev, current: 1 })); // 첫 페이지로 초기화
    listAxios2(1);
  }, [select, startDate, endDate]);

  // useEffect(() => {
  //   if (token) {
  //     const { search } = location;

  //     const pageParams = search.replace("?", "").split("&")[1];

  //     if (pageParams) {
  //       const page = Number(pageParams.split("=")[1]);
  //       listAxios(page);
  //     } else {
  //       listAxios(1);
  //     }
  //   }
  // }, []);

  return (
    <div className="history">
      <div className="filter-wrap">
        <div className="filter">
          <h4 className="tit">Filter by Balance History</h4>
          <FormControl fullWidth>
            <InputLabel id="balance">Select Balance</InputLabel>
            <Select
              labelId="balance"
              id=""
              label="Select Balance"
              value={select}
            >
              {SelectTypeFilter.map((it, idx) => (
                <MenuItem
                  key={idx}
                  value={it.code}
                  onClick={() => setSelect(it.code)}
                >
                  {it.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <button onClick={() => setLoading((prev) => !prev)}>test</button> */}
        </div>

        <div className="filter">
          <h4 className="tit">Search by Date Range</h4>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="datepicker-wrap">
              {/* Start date */}
              <CustomDatePicker
                label="Start Date"
                value={startDate}
                onChange={setStartDate}
              />
              {/* End date */}
              <CustomDatePicker
                label="End Date"
                value={endDate}
                onChange={setEndDate}
              />
            </div>
          </LocalizationProvider>
        </div>
      </div>

      <div className="table-wrap">
        <table>
          <tr>
            <th>Date</th>
            <th>Description</th>
            <th>Amount</th>
            <th>Balance</th>
          </tr>

          {loading ? (
            <tr className="h-[1021.3px]">
              <td colSpan={4}>
                <CircularProgress />
              </td>
            </tr>
          ) : // Deposit Tab
          list.length && select === "cash" ? (
            list.map((item) => (
              <tr key={item.idx}>
                <td>{new Date(item.createdAt.split(" ")[0]).toDateString()}</td>
                <td>
                  {" "}
                  {item.isPending && (
                    <span className="text-yellow-400 mr-2">(Pending)</span>
                  )}
                  {item.description}
                </td>

                <td>
                  {item.status === "U" ? "-" : "+"}$ {item.cash}
                </td>
                <td>$ {item.balance}</td>
              </tr>
            ))
          ) : // Winning Tab
          list.length && select === "prize" ? (
            list.map((item) => (
              <tr key={item.idx}>
                <td>{new Date(item.createdAt.split(" ")[0]).toDateString()}</td>

                <td>
                  {item.isPending && (
                    <span className="text-yellow-400 mr-2">(Pending)</span>
                  )}
                  {item.description}
                </td>
                <td>
                  {item.status === "U" ? "-" : "+"}$ {item.prize}
                </td>
                <td>$ {item.balance}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4}>
                <NoData />
              </td>
            </tr>
          )}
        </table>
      </div>
      <Pagination
        count={page.max}
        page={page.current}
        siblingCount={isFold ? 0 : 1}
        size={isFold ? "small" : "medium"}
        className="pagination"
        onChange={(_, value) => {
          setPage((prev) => ({ ...prev, current: value }));
          listAxios2(value);
        }}
      />
    </div>
  );
};

export default History;
