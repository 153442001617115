import React, { useEffect, useState } from "react";

interface Ball {
  white: number;
  special: number;
}

interface BallSet {
  [key: string]: Ball;
  mega: Ball;
  power: Ball;
}

const BallSet: BallSet = {
  mega: {
    white: 70,
    special: 25,
  },
  power: {
    white: 69,
    special: 26,
  },
};

interface ShowNumProps {
  nums: string;
  code: string;
  sysCode: string;
}

const ShowNums = ({ nums, code, sysCode }: ShowNumProps) => {
  const [showNums, setShowNums] = useState<boolean>(false);
  const [list, setList] = useState<number[][]>([]);

  const num = nums.split(",");
  const special = num.pop();
  const typeChk = (target: string) => {
    if (target === "MM") {
      return "mega";
    } else {
      return "power";
    }
  };

  /** 시스템 숫자 생성기 */
  const getCombinations = (arr: string[], selectNumber: number) => {
    const results: any[] = [];

    if (selectNumber === 1) return arr.map((value) => [value]);

    arr.forEach((fixed, index, origin) => {
      const rest = origin.slice(index + 1);
      const combinations = getCombinations(rest, selectNumber - 1);
      const attached = combinations.map((combination) => [
        fixed,
        ...combination,
      ]);
      results.push(...attached);
    });

    return results;
  };

  const settingNums = (type: string) => {
    if (type === "GUA" || type === "PIC") {
      return;
    } else {
      setList(getCombinations(num, 5));
    }
  };

  useEffect(() => {
    settingNums(code);
  }, []);

  const pickSpball = nums.split(",").pop();

  return (
    <div className="show-number">
      <div
        className={`btn style02 show ${showNums ? "open " : ""}`}
        onClick={() => {
          setShowNums(!showNums);
        }}
      >
        <span>{showNums ? "Hide your numbers" : "Show your numbers"}</span>
        <span className="material-symbols-rounded">keyboard_arrow_down</span>
      </div>
      {showNums ? (
        sysCode === "PIC" ? (
          <div className="number-wrap">
            {Array.from(
              { length: BallSet[typeChk(code)].white },
              (_, whiteIdx) => (
                <div className="number" key={whiteIdx}>
                  <p># {whiteIdx + 1}</p>
                  <div className="ball-wrap">
                    {num.map((it, idx) => (
                      <div className="ball" key={idx}>
                        {it === "G" ? whiteIdx + 1 : it}
                      </div>
                    ))}
                    <div
                      className={`ball ${
                        typeChk(code) === "mega" ? "mega" : "power"
                      }`}
                    >
                      {pickSpball}
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        ) : sysCode !== "GUA" ? (
          <div className="number-wrap">
            {list.map((it, idx) => (
              <div className="number" key={idx}>
                <p className="">#{idx + 1}</p>
                <div className="ball-wrap">
                  {it.slice(0, 5).map((num: any, idx: any) => (
                    <div className="ball" key={idx}>
                      {num}
                    </div>
                  ))}

                  <div
                    className={`ball ${
                      typeChk(code) === "mega" ? "mega" : "power"
                    }`}
                  >
                    {special}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="number-wrap">
            {Array.from(
              { length: BallSet[typeChk(code)].special },
              (_, specialIdx) => (
                <div className="number" key={specialIdx}>
                  <p className="">#{specialIdx + 1}</p>
                  <div className="ball-wrap">
                    {num.map((it, idx) => (
                      <div className="ball" key={idx}>
                        {it}
                      </div>
                    ))}
                    <div
                      className={`ball ${
                        typeChk(code) === "mega" ? "mega" : "power"
                      }`}
                    >
                      {specialIdx + 1}
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default ShowNums;
