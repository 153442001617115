import { useState } from "react";

// ui
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";

// img
import Logo from "../img/common/LC_logo_ani_h.svg";

const UserPage = () => {
  const [showPassword, setShowPassword] = useState({
    current: false,
    new: false,
    confirm: false,
  });

  return (
    <div className="user">
      <div className="star-wrap">
        <div className="star"></div>
        <div className="star2"></div>
        <div className="star3"></div>
      </div>

      <div className="user-wrap">
        <img
          src={Logo}
          alt="Lottery Cluster"
          className="logo btn"
          onClick={() => {
            window.location.href = "/";
          }}
        />

        <div className="user-cont">
          {/* Reset Password */}
          <div className="reset-pw">
            <h3>Would you like to change your password?</h3>

            <div className="input-wrap">
              <FormControl fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">
                  New Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword.new ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        // onClick={() => handleClickShowPassword("new")}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword.new ? (
                          <span className="material-symbols-outlined">
                            visibility
                          </span>
                        ) : (
                          <span className="material-symbols-outlined">
                            visibility_off
                          </span>
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="New Password"
                />
              </FormControl>
            </div>

            <div className="input-wrap">
              <FormControl fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">
                  Confirm New Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword.confirm ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        // onClick={() => handleClickShowPassword("confirm")}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword.confirm ? (
                          <span className="material-symbols-outlined">
                            visibility
                          </span>
                        ) : (
                          <span className="material-symbols-outlined">
                            visibility_off
                          </span>
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Confirm New Password"
                />
              </FormControl>
            </div>

            <div className="btn style01 lg spc">Reset Password</div>
            {/* Reset Password - END */}
          </div>
        </div>

        <div
          className="btn contact"
          onClick={() => {
            window.location.href = "/email";
          }}
        >
          Contact Us
        </div>
      </div>
    </div>
  );
};

export default UserPage;
