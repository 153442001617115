// tools
import { useState, useEffect } from "react";
import User from "../../tools/userInfo";
import amountMaker from "../../tools/amountMaker";

// ui
import Skeleton from "@mui/material/Skeleton";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

// components
import NoData from "../common/noData";
import DrawSubs from "../../pages/DESIGN/drawSubs";

//  img
import PowerBallLogo from "../../img/common/USA_Power_lottery.png";
import MegaBallLogo from "../../img/common/USA_Mega_lottery.png";
import createAxiosInstance from "../../api/axiosConfig";
import dayjs, { Dayjs } from "dayjs";
import {
  DatePicker,
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { error } from "console";
// import { isType } from "@babel/types";

interface Nums {
  idx: number;
  num: string;
}

interface List {
  auDrawTime: string;
  auOrderTime: string;
  createdAt: string;
  drawnum: number;
  game1: string | null;
  game2: string | null;
  game3: string | null;
  game4: string | null;
  game5: string | null;
  gameCnt: number;
  gameType: string;
  idx: number;
  jackpot: number;
  multiplier: number;
  prize: number;
  scanPath: null | string;
  scanStatus: string;
  status: string;
  winRank: null;
  winStatus: string;
  winningNumber: string;
}

interface Subscription {
  idx: number;
  productCode: string;
  productName: string;
  methodCode: string;
  systemCode: string;
  draws: number;
  cycle: number;
  multiplier: string;
  amount: string;
  stop_reason: string | null;
  status: string;
  createdAt: string;
  nums: Nums[];
}

export interface CustomDatePickerProps {
  label: string;
  value: Dayjs | null;
  onChange: (newValue: Dayjs | null) => void;
}

const SelectTypeFilter = [
  { code: "all", title: "All Lottery" },
  { code: "pb", title: "USA Power Lottery" },
  { code: "mm", title: "USA Mega Lottery" },
];

const Draws = () => {
  const { api, token, info } = User();
  const axiosInstance = createAxiosInstance(api, token);

  const [type, setType] = useState<string>("all");
  const [startDate, setStartDate] = useState<Dayjs | null>(
    dayjs().subtract(30, "day")
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());
  const [list, setList] = useState<List[]>([]);
  const [subList, setSubList] = useState();
  const [page, setPage] = useState<number>(1);
  const [hasMoreData, setHasMoreData] = useState<boolean>(true);

  const fetchData = async () => {
    try {
      const isType = type === "all" ? "" : type === "pb" ? "PB" : "MM";
      const sDate = startDate?.format("YYYY-MM-DD");
      const eDate = endDate?.format("YYYY-MM-DD");
      const endpoint = `/order?type=${isType}`;

      const res = await axiosInstance.get(endpoint, {
        params: {
          sDate,
          eDate,
          // perPage: 5,
          // page: newPage,
        },
      });
      setList(res.data.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getSubs = () => {
    axiosInstance
      .get("/subscription")
      .then((res) => console.log(res))
      .catch((error) => console.error(error));
  };
  useEffect(() => {
    getSubs();
  }, []);

  useEffect(() => {
    fetchData();
  }, [type, startDate, endDate]);

  // date Picker
  const CustomDay = (props: PickersDayProps<Dayjs>) => {
    const { day, ...other } = props;

    // 오늘 날짜 체크 (스타일 변경을 위해 남겨둠)
    const isToday = day.isSame(dayjs(), "day");

    return <PickersDay {...other} day={day} />;
  };

  const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
    label,
    value,
    onChange,
  }) => {
    return (
      <DatePicker
        label={label}
        value={value}
        onChange={onChange}
        shouldDisableDate={() => false}
        slots={{
          day: (dayProps) => <CustomDay {...dayProps} />,
        }}
        slotProps={{
          textField: {
            error: false,
          },
        }}
      />
    );
  };

  return (
    <div className="draw">
      <DrawSubs />
      <div className="draw-wrap">
        <div className="filter-wrap">
          <div className="filter">
            <h4 className="tit">Filter by Game</h4>
            <FormControl fullWidth>
              <InputLabel id="game">Select a Game</InputLabel>
              <Select labelId="game" id="" label="Select a Game" value={type}>
                {SelectTypeFilter.map((it, idx) => (
                  <MenuItem
                    key={idx}
                    value={it.code}
                    onClick={() => setType(it.code)}
                  >
                    {it.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="filter">
            <h4 className="tit">Search by Date Range</h4>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div className="datepicker-wrap">
                {/* Start date */}
                <CustomDatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={setStartDate}
                />
                {/* End date */}
                <CustomDatePicker
                  label="End Date"
                  value={endDate}
                  onChange={setEndDate}
                />
              </div>
            </LocalizationProvider>
          </div>
        </div>

        <div className="draw-list">
          {list.length > 0 ? (
            list.map((item) => <Item key={item.idx} data={item} />)
          ) : (
            <NoData />
          )}
        </div>
      </div>
    </div>
  );
};

export default Draws;

const Item = ({ data }: { data: List }) => {
  const { api } = User();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const [result, setResult] = useState<{ numbers: string[]; prize: number }>({
    numbers: [],
    prize: 0,
  });
  const game = data.gameType === "MM" ? "mega" : "power";

  const fullName =
    data.gameType === "MM" ? "USA Mega Lottery" : "USA Power Lottery";

  const myNumbers = [
    data.game1,
    data.game2,
    data.game3,
    data.game4,
    data.game5,
  ];

  const white: string[] = [
    result.numbers[0],
    result.numbers[1],
    result.numbers[2],
    result.numbers[3],
    result.numbers[4],
  ];

  useEffect(() => {
    if (data) {
      setResult({
        numbers: data.winningNumber ? data.winningNumber.split(",") : [],
        prize: data.jackpot || 0,
      });
      setLoading(false);
    }
  }, []);

  const placeConverter = (place: number) => {
    const num = Number(place);

    if (num) {
      switch (num) {
        case 1:
          return "1st";
          break;

        case 2:
          return "2nd";
          break;

        case 3:
          return "3rd";
          break;

        default:
          return `${num}th`;
      }
    } else {
      return "";
    }
  };

  const RankText = (ranks: string | null) => {
    let rankText = "";
    if (ranks) {
      const rankArray = ranks
        .split(",")
        .map((item) => Number(item))
        .sort();

      for (let i = 0; i < rankArray.length; i++) {
        const result = placeConverter(rankArray[i]);

        if (result) {
          if (rankText) {
            rankText = rankText + "/" + result;
          } else {
            rankText = result;
          }
        }
      }
    }

    return rankText;
  };

  const reward = amountMaker(result.prize);

  const imageSource = () => {
    location.href = `/ticket?code=${data.idx}`;
  };

  const goGame = (type: string) => {
    if (type === "PB") {
      window.location.href = "/power?Q";
    } else {
      window.location.href = "/mega?Q";
    }
  };

  return (
    <div className="item">
      <div className="summary">
        <div className="name-tag-wrap">
          <p className="name">
            {game == "mega" ? "USA Mega Lottery" : "USA Power Lottery"}
          </p>
          <div className="tag-wrap">
            <p className="tag multiplier">Multiplier</p>
            <p className="tag syndicate">Syndicate</p>
            <p className="tag system">Pick 1 Less</p>
          </div>
        </div>

        <div className="prize-result-wrap">
          <img
            src={game == "mega" ? MegaBallLogo : PowerBallLogo}
            alt={game == "mega" ? "USA Mega Lottery" : "USA Power Lottery"}
            className="game-logo"
          />
          <p className="prize">
            <span className="unit">{reward.mark} </span>
            {loading ? (
              <Skeleton variant="rounded" width={300} height={50} />
            ) : (
              reward.amount
            )}
            <span className="unit"> {reward.unit}</span>
          </p>

          <div className="result">
            {data.winStatus === "R" ? (
              <p>Draw Pending</p>
            ) : data.winStatus === "N" ? (
              <p>No win</p>
            ) : (
              <p className="win">
                {RankText(data.winRank)} place <span>(${data.prize})</span>
              </p>
            )}
          </div>
          <div className="btn style01 md" onClick={() => goGame(data.gameType)}>
            PLAY AGAIN
          </div>
        </div>
      </div>

      {!open && (
        <div className="btn style02" onClick={() => setOpen((prev) => !prev)}>
          <span>See full draw details</span>
          <span className="material-symbols-rounded">expand_more</span>
        </div>
      )}

      {open && (
        <div className="detail">
          {result.numbers.length > 3 && (
            <div className="latest-winning-number">
              <p className="tit">Winning Numbers</p>
              <div className="ball-wrap">
                {result.numbers.map((item, index) =>
                  index === 5 ? (
                    <div
                      key={`${data.drawnum}_${item}_${index}`}
                      className={`ball ${game}`}
                    >
                      {item}
                    </div>
                  ) : (
                    <div
                      key={`${data.drawnum}_${item}_${index}`}
                      className="ball"
                    >
                      {item}
                    </div>
                  )
                )}
              </div>
              <p className="multi">Multiplier {data.multiplier}x</p>
            </div>
          )}

          <div className="myball-list">
            {myNumbers.map((num, index) =>
              num ? (
                <>
                  <p className="game-num">Game #{index + 1}</p>
                  <div className="ball-wrap" key={`${index}_${num}`}>
                    {num?.split(",").map((item, i) =>
                      i === 5 ? (
                        <div
                          key={`${data.drawnum}_${index}_${item}}_${i}`}
                          className={`ball ${game} ${
                            result.numbers[5] == item ? "matching" : ""
                          }`}
                        >
                          {item}
                        </div>
                      ) : (
                        <div
                          key={`${data.drawnum}_${index}_${item}}_${i}`}
                          className={`ball ${
                            white.includes(item) ? "matching" : ""
                          } `}
                        >
                          {item}
                        </div>
                      )
                    )}
                  </div>
                </>
              ) : (
                <></>
              )
            )}
          </div>

          <ul className="draw-info">
            <li>
              Total: ${data.gameCnt * 7}, Draw:&nbsp;
              {new Date(data.auDrawTime).toLocaleString("en-AU")}
            </li>

            <li>
              Purchase:&nbsp;
              {new Date(data.createdAt).toLocaleString("en-AU")}
            </li>
          </ul>

          <div className="btn-wrap">
            <div className="btn style02" onClick={() => imageSource()}>
              <span>View ticket</span>
            </div>
            <div className="btn style02" onClick={() => imageSource()}>
              <span>View receipt</span>
            </div>
          </div>

          <div className="btn style02" onClick={() => setOpen((prev) => !prev)}>
            <span>Hide draw details</span>
            <span className="material-symbols-rounded">expand_less</span>
          </div>
        </div>
      )}
    </div>
  );
};
