import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import IconButton from "@mui/material/IconButton";
import RadioGroup from "@mui/material/RadioGroup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// img
import Google from "../img/common/google-ico.svg";
import Facebook from "../img/common/facebook-ico.svg";
import Apple from "../img/common/apple-ico.svg";

// tools
import User from "../tools/userInfo";
import NumberOnly from "../tools/numberOnly";
import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { Dayjs } from "dayjs";
import { FormHelperText, OutlinedInput } from "@mui/material";
import SignupComplete from "./signUpComplete";
import createAxiosInstance from "../api/axiosConfig";
import { border } from "@mui/system";
import { error } from "console";

interface Info {
  email: string;
  password: string;
  gender: string;
  firstName: string;
  lastName: string;
  phone: string;
  birth: string;
  unit: string;
  number: string;
  address: string;
  city: string;
  country: string;
  notify: string;
  marketing: string;
  provider: string;
}

interface CheckInfo {
  email: boolean;
  password: boolean;
  firstName: boolean;
  lastName: boolean;
  phone: boolean;
  birth: boolean;
  gender: boolean;
  unit: boolean;
  number: boolean;
  address: boolean;
  city: boolean;
  country: boolean;
}

const Signup = () => {
  const { api, token } = User();
  const axiosInstance = createAxiosInstance(api, token);
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [check, setCheck] = useState(false);
  const [info, setInfo] = useState<Info>({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    phone: "",
    birth: "",
    gender: "",
    unit: "",
    number: "",
    address: "",
    city: "",
    country: "",
    notify: "Y",
    marketing: "Y",
    provider: "",
  });

  const [checkList, setCheckList] = useState<CheckInfo>({
    email: false,
    password: false,
    firstName: false,
    lastName: false,
    phone: false,
    birth: false,
    gender: false,
    unit: false,
    number: false,
    address: false,
    city: false,
    country: false,
  });

  const [social, setSocial] = useState(false);
  const [agree, setAgree] = useState(false);
  const navigate = useNavigate();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleData = (key: string, value: string) => {
    if (key === "number" || key === "phone") {
      setInfo((prev) => ({ ...prev, [key]: NumberOnly(value) }));
    } else {
      setInfo((prev) => ({ ...prev, [key]: value }));
    }
  };

  const confirm = document.getElementById("confirm") as HTMLInputElement;

  // password regex
  const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d]{8,21}$/;
  // console.log(regex.test(info.password));

  // 유효성 검사 함수
  const validateInputs = () => {
    const emailValid = confirm?.value === info.email && info.email.length > 1;
    const passwordValid = regex.test(info.password);
    const genderValid = ["male", "female", "other"].includes(info.gender);
    const firstNameValid = info.firstName.length > 2;
    const lastNameValid = info.lastName.length > 2;
    const phoneValid = info.phone.length > 1;
    const birthValid = info.birth.length > 1;
    const addressValid = info.address.length > 1;
    const unitValid = info.unit.length > 1;
    const numberValid = info.number.length > 1;
    const cityValid = info.city.length > 1;
    const countryValid = info.country.length > 1;

    setCheckList({
      email: emailValid,
      password: passwordValid,
      gender: genderValid,
      firstName: firstNameValid,
      lastName: lastNameValid,
      phone: phoneValid,
      birth: birthValid,
      address: addressValid,
      unit: unitValid,
      number: numberValid,
      city: cityValid,
      country: countryValid,
    });

    // 모든 조건이 충족되었는지 반환
    return (
      emailValid &&
      passwordValid &&
      genderValid &&
      firstNameValid &&
      lastNameValid &&
      phoneValid &&
      birthValid &&
      addressValid &&
      unitValid &&
      numberValid &&
      cityValid &&
      countryValid
    );
  };

  useEffect(() => {
    if (token) {
      setTimeout(() => {
        navigate("/welcome", { replace: true });
      }, 2000);
    }
  }, [token]);

  useEffect(() => {
    const socialCheck = sessionStorage.getItem("social");
    if (socialCheck) {
      const parse = JSON.parse(socialCheck);
      setSocial(true);
      setInfo((prev) => ({
        ...prev,
        ...parse,
      }));
      setTimeout(() => sessionStorage.clear(), 50);
    }
  }, []);

  /** sign check */
  // const checkout = () => {
  //   if (agree) {
  //     if (confirm.value === info.email) {
  //       // if (info.password.length > 7 && info.password.length < 21) {
  //       if (regex.test(info.password)) {
  //         if (!info.gender) {
  //           handleData("gender", "other");
  //         }
  //         if (info.firstName) {
  //           if (info.lastName) {
  //             if (info.phone) {
  //               if (info.birth) {
  //                 if (info.address) {
  //                   if (info.city) {
  //                     if (info.country) {
  //                       register();
  //                     } else {
  //                       Swal.fire({
  //                         title: "Select your country",
  //                         icon: "error",
  //                         allowOutsideClick: false,
  //                       });
  //                     }
  //                   } else {
  //                     Swal.fire({
  //                       title: "City is required",
  //                       icon: "error",
  //                       allowOutsideClick: false,
  //                     });
  //                   }
  //                 } else {
  //                   Swal.fire({
  //                     title: "Address is required",
  //                     icon: "error",
  //                     allowOutsideClick: false,
  //                   });
  //                 }
  //               } else {
  //                 Swal.fire({
  //                   title: "Date of birth is required",
  //                   icon: "error",
  //                   allowOutsideClick: false,
  //                 });
  //               }
  //             } else {
  //               Swal.fire({
  //                 title: "Phone number is required",
  //                 icon: "error",
  //                 allowOutsideClick: false,
  //               });
  //             }
  //           } else {
  //             Swal.fire({
  //               title: "Last name is required",
  //               icon: "error",
  //               allowOutsideClick: false,
  //             });
  //           }
  //         } else {
  //           Swal.fire({
  //             title: "First name is required",
  //             icon: "error",
  //             allowOutsideClick: false,
  //           });
  //         }
  //       } else {
  //         Swal.fire({
  //           title: "Please meet the password requirements",
  //           text: "Be at least 8 characters long and no more than 21 characters, Contain at least one uppercase letter, Contain at least one lowercase letter, Include at least one number",
  //           icon: "error",
  //           allowOutsideClick: false,
  //         });
  //       }
  //     } else {
  //       Swal.fire({
  //         title: "Email and Confirm Email do not match",
  //         icon: "error",
  //         allowOutsideClick: false,
  //       });
  //     }
  //   } else {
  //     Swal.fire({
  //       title: "You must agree to the terms and conditions",
  //       icon: "error",
  //       allowOutsideClick: false,
  //     });
  //   }
  // };

  /** sign check 2 */
  const checkout = () => {
    setCheck(true);
    if (agree) {
      if (validateInputs()) {
        register();
      } else {
        Swal.fire({
          title: "Please meet all the input requirements.",
          icon: "error",
          allowOutsideClick: false,
        });
      }
    } else {
      Swal.fire({
        title: "You must agree to the terms and conditions.",
        icon: "error",
        allowOutsideClick: false,
      });
    }
  };

  const register = () => {
    axiosInstance
      .post(`/register`, info)
      .then(({ data }) => {
        if (data.message === "success") {
          navigate(`/welcome`);
        }
      })
      .catch(({ response }) => {
        Swal.fire({
          title: response.data.message,
          icon: "error",
          allowOutsideClick: false,
        });
      });
  };

  const googleLogin = useGoogleLogin({
    scope: "email profile",
    flow: "auth-code",
    ux_mode: "redirect",
    redirect_uri: `${location.origin}/auth/google`,
    state: location.href,
  });

  const isSocial = () => {
    if (info.email) {
      return info.email;
    } else {
      ("Email");
    }
  };

  // const socialCheckout = () => {
  //   if (agree) {
  //     if (!info.gender) {
  //       handleData("gender", "other");
  //     }
  //     if (info.firstName) {
  //       if (info.lastName) {
  //         if (info.phone) {
  //           if (info.birth) {
  //             if (info.address) {
  //               if (info.city) {
  //                 if (info.country) {
  //                   socialRegister();
  //                 } else {
  //                   Swal.fire({
  //                     title: "Select your country",
  //                     icon: "error",
  //                     allowOutsideClick: false,
  //                   });
  //                 }
  //               } else {
  //                 Swal.fire({
  //                   title: "City is required",
  //                   icon: "error",
  //                   allowOutsideClick: false,
  //                 });
  //               }
  //             } else {
  //               Swal.fire({
  //                 title: "Address is required",
  //                 icon: "error",
  //                 allowOutsideClick: false,
  //               });
  //             }
  //           } else {
  //             Swal.fire({
  //               title: "Date of birth is required",
  //               icon: "error",
  //               allowOutsideClick: false,
  //             });
  //           }
  //         } else {
  //           Swal.fire({
  //             title: "Phone number is required",
  //             icon: "error",
  //             allowOutsideClick: false,
  //           });
  //         }
  //       } else {
  //         Swal.fire({
  //           title: "Last name is required",
  //           icon: "error",
  //           allowOutsideClick: false,
  //         });
  //       }
  //     } else {
  //       Swal.fire({
  //         title: "First name is required",
  //         icon: "error",
  //         allowOutsideClick: false,
  //       });
  //     }
  //   } else {
  //     Swal.fire({
  //       title: "You must agree to the terms and conditions",
  //       icon: "error",
  //       allowOutsideClick: false,
  //     });
  //   }
  // };

  const socialCheckout = () => {
    setCheck(true);
    if (agree) {
      if (validateInputs()) {
        socialRegister();
      } else {
        Swal.fire({
          title: "Please meet all the input requirements.",
          icon: "error",
          allowOutsideClick: false,
        });
      }
    } else {
      Swal.fire({
        title: "You must agree to the terms and conditions.",
        icon: "error",
        allowOutsideClick: false,
      });
    }
  };

  const socialRegister = () => {
    axiosInstance
      .post(`/register`, info)
      .then(({ data }) => {
        if (data.message === "success") {
          navigate(`/welcome`);
        }
      })
      .catch(({ response }) => {
        Swal.fire({
          title: response.data.message,
          icon: "error",
          allowOutsideClick: false,
        });
      });
  };

  return (
    <>
      {location.pathname === "/signup" ? (
        <div className="signup-wrap">
          <div className="sub-top-area">
            <h3 className="tit">Welcome to the Lottery Cluster!</h3>
            {/* <p className="sub-tit">Sign up with your email to get started!</p> */}
          </div>

          <div className="sns-area">
            <h4>Signup with</h4>
            <div className="sns-wrap">
              {(info.provider === "" || info.provider === "google") && (
                <img
                  src={Google}
                  alt="Google login"
                  className="btn"
                  onClick={() => {
                    googleLogin();
                  }}
                />
              )}

              {(info.provider === "" || info.provider === "facebook") && (
                <img src={Facebook} className="btn" alt="Facebook login" />
              )}
              {(info.provider === "" || info.provider === "apple") && (
                <img src={Apple} className="btn" alt="Apple login" />
              )}
            </div>
          </div>
          {info.provider === "" && <p className="or">or</p>}
          <form>
            <div className="input-wrap">
              <TextField
                type="email"
                label="Email"
                sx={{
                  width: "100%",
                }}
                onChange={({ target }) => handleData("email", target.value)}
                disabled={social}
                value={isSocial()}
                error={!checkList.email && check}
                helperText={!checkList.email && check ? "Required field" : ""}
              />
            </div>
            <div className="input-wrap">
              <TextField
                id="confirm"
                type="email"
                label="Confirm email"
                fullWidth
                error={!checkList.email && check}
                helperText={
                  !checkList.email && check ? "The emails do not match." : ""
                }
              />
            </div>

            <FormControl fullWidth>
              <InputLabel htmlFor="password">Password</InputLabel>
              <OutlinedInput
                onChange={({ target }) => handleData("password", target.value)}
                id="password"
                name="pw"
                error={!checkList.password && check}
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <span className="material-symbols-rounded">
                        visibility
                      </span>
                    ) : (
                      <span className="material-symbols-rounded">
                        visibility_off
                      </span>
                    )}
                  </IconButton>
                }
                label="Password"
              />
              {!checkList.password && check && (
                <FormHelperText error>
                  Password is invalid. Min. 8 chars. Must include uppercase,
                  lowercase, and number.
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              component="fieldset"
              error={!checkList.gender && check} // 에러 상태
            >
              <RadioGroup
                row
                name="row-radio-buttons-group"
                value={info.gender}
                onChange={({ target }) => handleData("gender", target.value)}
              >
                <FormControlLabel
                  value="male"
                  control={<Radio checked={info.gender === "male"} />}
                  label="Male"
                />
                <FormControlLabel
                  value="female"
                  control={<Radio checked={info.gender === "female"} />}
                  label="Female"
                />
                <FormControlLabel
                  value="other"
                  control={<Radio checked={info.gender === "other"} />}
                  label="Ohter"
                />
              </RadioGroup>
              <FormHelperText>
                {!checkList.gender && check ? "Please select your title." : ""}
              </FormHelperText>
            </FormControl>

            <div className="input-wrap">
              <TextField
                label="First Name"
                value={info.firstName}
                onChange={({ target }) => handleData("firstName", target.value)}
                fullWidth
                error={!checkList.firstName && check}
                helperText={
                  !checkList.firstName && check
                    ? "First name must be a minimum of 2 char."
                    : ""
                }
              />
              <TextField
                label="Last Name"
                onChange={({ target }) => handleData("lastName", target.value)}
                value={info.lastName}
                fullWidth
                error={!checkList.lastName && check}
                helperText={
                  !checkList.lastName && check
                    ? "Last name must be a minimum of 2 char."
                    : ""
                }
              />
            </div>
            <div className="input-wrap">
              <TextField
                type="tel"
                label="Phone Number"
                fullWidth
                value={info.phone}
                onChange={({ target }) => handleData("phone", target.value)}
                inputProps={{
                  maxLength: 10,
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
                error={!checkList.phone && check}
                helperText={
                  !checkList.phone && check
                    ? "The phone number format is invalid. Please try again."
                    : ""
                }
              />
            </div>
            <div className="input-label-wrap">
              <FormControl fullWidth error={!checkList.birth && check}>
                <label htmlFor="">Date of Birth</label>
                <div className="input-wrap">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{
                        ...(!checkList.birth &&
                          check && {
                            border: "0.5px solid red",
                            borderRadius: "4px",
                          }),
                      }}
                      slotProps={{ textField: { fullWidth: true } }}
                      onChange={(e: Dayjs | null) => {
                        if (e) handleData("birth", e.format("YYYYMMDD"));
                      }}
                    />
                  </LocalizationProvider>
                </div>
                <FormHelperText>
                  {!checkList.birth && check ? "Invaild Birth Date." : ""}
                </FormHelperText>
              </FormControl>
            </div>
            <div className="input-label-wrap">
              <label htmlFor="">Address</label>
              <div className="input-wrap">
                <TextField
                  label="Unit/Flat"
                  fullWidth
                  value={info.unit}
                  onChange={({ target }) => handleData("unit", target.value)}
                  error={!checkList.unit && check}
                  helperText={!checkList.unit && check ? "Required field." : ""}
                />
                <TextField
                  type="tel"
                  label="Street Number"
                  fullWidth
                  value={info.number}
                  onChange={({ target }) => handleData("number", target.value)}
                  error={!checkList.number && check}
                  helperText={
                    !checkList.number && check ? "Required field." : ""
                  }
                />
              </div>
              <div className="input-wrap">
                <TextField
                  label="Address"
                  fullWidth
                  value={info.address}
                  onChange={({ target }) => handleData("address", target.value)}
                  error={!checkList.address && check}
                  helperText={
                    !checkList.address && check ? "Required field." : ""
                  }
                />
              </div>
              <div className="input-wrap">
                <TextField
                  label="City / Suburb"
                  fullWidth
                  value={info.city}
                  onChange={({ target }) => handleData("city", target.value)}
                  error={!checkList.city && check}
                  helperText={!checkList.city && check ? "Required field." : ""}
                />
                <FormControl fullWidth error={!checkList.country && check}>
                  <InputLabel id="demo-simple-select-label">
                    Select Country
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Select Country"
                    value={info.country}
                    onChange={({ target }) =>
                      handleData("country", target.value)
                    }
                  >
                    <MenuItem value="AU">Australia</MenuItem>
                    <MenuItem value="NZ">New Zealand</MenuItem>
                  </Select>
                  <FormHelperText>
                    {!checkList.country && check ? "Select your country" : ""}
                  </FormHelperText>
                </FormControl>
              </div>
            </div>
            <div className="input-label-wrap">
              <label htmlFor="">Notifications</label>
              <div className="input-wrap">
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      onChange={({ target }) =>
                        handleData("notify", target.checked ? "Y" : "N")
                      }
                    />
                  }
                  label="SMS notifications"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      onChange={({ target }) =>
                        handleData("marketing", target.checked ? "Y" : "N")
                      }
                    />
                  }
                  label="App Push"
                />
              </div>
            </div>
            <div className="input-wrap agree">
              <FormControlLabel
                value=""
                control={
                  <Checkbox
                    value={agree}
                    onChange={({ target }) => setAgree(target.checked)}
                  />
                }
                label={
                  <p>
                    I have read The Lottery Cluster’s{" "}
                    <a href="/privacy" target="_blank">
                      privacy policy
                    </a>{" "}
                    and agree to the{" "}
                    <a href="/terms" target="_blank">
                      terms and conditions
                    </a>
                    .
                  </p>
                }
              />
            </div>
            <p className="mt-[-30px] mb-6 text-center">
              To continue, please confirm you agree to our terms and conditions.
            </p>
            <div
              className="btn style01 md spc"
              style={{ width: "100%" }}
              onClick={() => {
                if (social) {
                  setCheck(true);
                  socialCheckout();
                } else {
                  setCheck(true);
                  checkout();
                }
              }}
            >
              <span>Create account Now</span>
            </div>
          </form>
        </div>
      ) : location.pathname === "/welcome" ? (
        <SignupComplete info={info} />
      ) : (
        <></>
      )}
    </>
  );
};
export default Signup;
