// tools
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";

import { GoogleOAuthProvider } from "@react-oauth/google";
import { Outlet } from "react-router-dom";
import User from "./tools/userInfo";
import createAxiosInstance from "./api/axiosConfig";

// img
import DefaultBg01 from "./img/common/default-bg01.png";
import DefaultBg02 from "./img/common/default-bg02.svg";
import DefaultBgSignup from "./img/sub/signup-bg.png";

// layout base
import Header from "./components/common/header";

import Footer from "./components/common/footer";
import NavBar from "./pages/DESIGN/BAR/navBar";
import Wrapper from "./components/common/wrapper";
import SideBar from "./components/common/sideBar";
//스크롤 프로그레스
import ScrollProgress from "./components/common/scrollProg";

// type
export interface LottoProps {
  auDrawTime: string;
  auOrderTime: string;
  currency: string;
  dayWeek: number;
  drawnum: number;
  exchange: string;
  initial: number;
  isDrawdate: number;
  jackpot: number;
  playdate: string;
  type: string;
  usDrawTime: string;
  latestNumber: string;
  latestDrawTime: string;
}

// Context 생성
const LottoContext = createContext<
  | {
      lottery: LottoProps[];
      loading: boolean;
      open: boolean;
      setOpen: Dispatch<SetStateAction<boolean>>;
      navOpen: boolean;
      setNavOpen: Dispatch<SetStateAction<boolean>>;
      limitCart: number;
      noLoginCart: number;
      setNoLoginCart: Dispatch<SetStateAction<number>>;
      checkNoLoginCart: () => void;
    }
  | undefined
>(undefined);

export const useLotto = () => {
  const context = useContext(LottoContext);
  if (!context) {
    throw new Error("useLotto must be used within a LottoProvider");
  }
  return context;
};

const LottoProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { api } = User();
  const [lottery, setLottery] = useState<LottoProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [navOpen, setNavOpen] = useState<boolean>(false);

  // cartLimit setup
  const [limitCart, setLimitCart] = useState<number>(20);
  const [noLoginCart, setNoLoginCart] = useState<number>(0);

  // fetch
  const axiosInstance = createAxiosInstance(api, "");

  // 당첨 및 추첨 정보
  useEffect(() => {
    axiosInstance
      .get(`/purchase`)
      .then(({ data }) => {
        if (data.message === "success") {
          setLottery(data.data);
          setTimeout(() => setLoading(false), 500);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [api]);

  const checkNoLoginCart = () => {
    const existingData = localStorage.getItem("noLgCart");
    const noLgCartArray = existingData ? JSON.parse(existingData) : [];

    setNoLoginCart(noLgCartArray.length);
  };

  return (
    <LottoContext.Provider
      value={{
        lottery,
        loading,
        open,
        setOpen,
        navOpen,
        setNavOpen,
        limitCart,
        noLoginCart,
        setNoLoginCart,
        checkNoLoginCart,
      }}
    >
      {children}
    </LottoContext.Provider>
  );
};

function App() {
  // mobile check
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const [isScrolled, setIsScrolled] = useState(false);
  const [showSideBar, setShowSideBar] = useState(
    location.pathname !== "/signup"
  );

  useEffect(() => {
    if (isMobile && location.href.includes("www")) {
      const m = location.href.replace("www", "m");
      location.replace(m);
    }
  }, [location]);

  // Header bg effect
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setShowSideBar(
      !location.pathname.startsWith("/welcome") &&
        !location.pathname.startsWith("/signup")
    );
  }, [location.pathname]);

  return (
    <GoogleOAuthProvider clientId="1710970034-r4l1dgphpsorfqh01upn8ogjmglf143f.apps.googleusercontent.com">
      <LottoProvider>
        {/* Default bg */}
        <img src={DefaultBg01} alt="bg-1" className="default-bg01" />
        <div className="star-wrap">
          <div className="star"></div>
          <div className="star2"></div>
          <div className="star3"></div>
        </div>
        {!location.pathname.startsWith("/signup") && (
          <img src={DefaultBg02} alt="bg-2" className="default-bg02" />
        )}

        {location.pathname.startsWith("/signup") && (
          <img
            src={DefaultBgSignup}
            alt="Signup"
            className="default-bg-signup"
          />
        )}
        <ScrollProgress />
        <Header isScrolled={isScrolled} />

        <div className="cont-wrapper inner">
          {!location.pathname.startsWith("/signup") &&
            !location.pathname.startsWith("/welcome") && <SideBar />}
          <div className={showSideBar ? "cont" : "cont signup"}>
            <Wrapper>
              <Outlet />
            </Wrapper>
            <Footer />
          </div>
        </div>
        {/* {!location.pathname.startsWith("/mega") &&
          !location.pathname.startsWith("/power") &&
          !location.pathname.startsWith("/signup") &&
          !location.pathname.startsWith("/welcome") && <NavBar />} */}
        <NavBar />
      </LottoProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
