// tools
import { Dispatch, SetStateAction, useState } from "react";

// ui
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";

interface ModalContent {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const SmsNotification = ({ setOpen }: ModalContent) => {
  const [selectedValue, setSelectedValue] = useState("all");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  return (
    <div className="notification sms">
      <div className="modal-tit-wrap">
        <h3 className="tit">SMS Option</h3>
      </div>

      <div className="modal-cont-wrap">
        <FormControl>
          <RadioGroup
            aria-labelledby="email-option"
            defaultValue="all"
            name="email-option"
            value={selectedValue}
            onChange={handleChange}
            sx={{ width: "fit-contents", margin: "0 auto" }}
          >
            <div className="input-wrap">
              <FormControlLabel
                value="all"
                control={<Radio />}
                label="Subscribe to all news and benefits"
              />
              {selectedValue === "all" && (
                <p className="subtxt">
                  Receive all our latest news, offers, and special promotions
                  via SMS. Be the first to know about everything!
                </p>
              )}
            </div>
            <div className="input-wrap">
              <FormControlLabel
                value="undate"
                control={<Radio />}
                label="Receive updates on Lotterys and Syndicates"
              />
              {selectedValue === "undate" && (
                <p className="subtxt">
                  Opt out of app push notifications. You can always update your
                  preferences later
                </p>
              )}
            </div>

            <div className="input-wrap">
              <FormControlLabel
                value="related"
                control={<Radio />}
                label="Account-related notifications only"
              />
              {selectedValue === "related" && (
                <p className="subtxt">
                  Stay in the loop with SMS alerts on account-related matters,
                  such as security updates or account changes
                </p>
              )}
            </div>
            <div className="input-wrap">
              <FormControlLabel value="none" control={<Radio />} label="none" />
              {selectedValue === "none" && (
                <p className="subtxt">
                  No SMS notifications will be sent to you. You can always
                  change this later
                </p>
              )}
            </div>
          </RadioGroup>
        </FormControl>
      </div>

      <div className="modal-bottom-wrap">
        <div className="btn-wrap">
          <div className="btn style01 sm" onClick={() => setOpen(false)}>
            Cancel
          </div>
          <div
            className="btn style01 sm white"
            style={{ padding: "12px 36px" }}
          >
            Save
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmsNotification;
