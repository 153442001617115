import Watch from "../img/sub/magazine-watch.png";
import Car from "../img/sub/magazine-car.png";
import Yacht from "../img/sub/magazine-yacht.png";

export const MegazineMenu = [
  {
    id: 1,
    text: "Magazine",
    menu: "home",
  },
  {
    id: 2,
    text: "Real estate",
    menu: "estate",
  },
  {
    id: 3,
    text: "Car",
    menu: "car",
  },
  {
    id: 4,
    text: "Watch",
    menu: "watch",
  },
  {
    id: 5,
    text: "Yacht",
    menu: "yacht",
  },
];

/** Magazine - Home - TextCard */
export const MagazineTxt = [
  { id: 1, text: "More Choice", content: "3,300+ Watches From Over 60 Brands" },
  {
    id: 2,
    text: "More Satisfaction",
    content: "24 Month Warranty On Every Watch",
  },
  { id: 3, text: "More Expertise", content: "Brand Accredited Service Centre" },
];

/** Magazine - Home - ItemCard */
export const MegazineCate = [
  {
    id: 1,
    img: Watch,
    text: "Rolex",
    text2: "Watches",
    href: "watch",
  },
  {
    id: 2,
    img: Car,
    text: "Luxury",
    text2: "Cars",
    href: "car",
  },
  {
    id: 3,
    img: Yacht,
    text: "Luxury",
    text2: "Yachts",
    href: "yacht",
  },
];

/**
  New Design Magazine - Mainbar
 */

export const MagazineBarTab = [
  {
    id: 1,
    tab: "All",
  },
  {
    id: 2,
    tab: "Real Estate",
  },
  {
    id: 3,
    tab: "Watches",
  },
  {
    id: 4,
    tab: "Yachts",
  },
  {
    id: 5,
    tab: "Cars",
  },
  // {
  //   id: 6,
  //   tab: "Life",
  // },
  // {
  //   id: 7,
  //   tab: "Tech",
  // },
];
