//  img
import Power from "../../img/common/USA_Power_lottery.png";
import Mega from "../../img/common/USA_Mega_lottery.png";
import Slider from "react-slick";

// style
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const DrawSubs = () => {
  // Slick slide
  const SliderSettings = {
    autoplay: true,
    speed: 500,
    dots: true,
    dotsClass: "custom-dots",
    arrow: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1000, // 화면 크기 반응형
        settings: {
          slidesToShow: 1, // 보여줄 슬라이드 개수
        },
      },
    ],
  };

  return (
    <div className="draw-syndicate-wrap">
      <div className="tit-wrap">
        <h4 className="tit">Subscription List</h4>
        {/* 신디케이트 생성 수 / 생성 가능한 전체 수(예시) */}
        <p>5 / 5</p>
      </div>

      <Slider {...SliderSettings}>
        {[1, 2, 3, 4, 5].map((a, i) => {
          return (
            <div className="syndicate-list" key={i}>
              <div className="item mr-5">
                <div className="name-tag-wrap">
                  <p className="name">#{i + 1} USA Power Lottery</p>
                  <div className="tag-wrap">
                    <p className="tag multiplier">Multiplier</p>
                    <p className="tag syndicate">Syndicate</p>
                    <p className="tag system">Pick 1 Less</p>
                  </div>
                </div>
                <div className="count-wrap">
                  <img src={Power} alt="" />
                  <div className="txt-wrap">
                    <p className="prize">$ 100 Million</p>
                    <p className="count">
                      <span className="tit">Count : </span>
                      <span className="remain">20</span>/50
                    </p>
                    <p className="type">
                      Rolling Subscription{" "}
                      <span className="start-date">(22/10/2024 ~)</span>
                    </p>
                  </div>
                </div>

                <div className="btn-wrap">
                  {/* pause 됐을 때는 Restore 버튼으로 변경 */}
                  <div className="btn style01 sm">Pause</div>
                  <div className="btn style01 sm">Cancel</div>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default DrawSubs;
