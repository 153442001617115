import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// img
import NavBarIcon1 from "./icon_navBar_menu.svg";
import NavBarIcon2 from "./icon_navBar_mega.svg";
import NavBarIcon3 from "./icon_navBar_power.svg";
import NavBarIcon4 from "./icon_navBar_results.svg";
import NavBarIcon5 from "./icon_navBar_mypage.svg"; //로그인 후
import NavBarIcon6 from "./icon_navBar_login.svg"; //로그인 전
import { useLotto } from "../../../App";

import User from "../../../tools/userInfo";

interface SideMenu {
  title: string;
  icon: any;
  href: string;
}

const Menubar: SideMenu[] = [
  { title: "USA Mega Lottery", icon: NavBarIcon2, href: "/mega" },
  { title: "USA Power Lottery", icon: NavBarIcon3, href: "/power" },
  { title: "Results", icon: NavBarIcon4, href: "/result2" },
  // { title: "MyPage", icon: NavBarIcon5, href: "/mypage" },
];

const NavBar = () => {
  const { navOpen, setNavOpen, open, setOpen } = useLotto();
  const { info, token } = User();
  const [focus, setFocus] = useState<string>("");
  const navigate = useNavigate();

  const clickMenu = (target: string, href: string) => {
    if (href === "/power" || href === "/mega") {
      // location.href = href;
      navigate(href);
      setFocus(target);
      setNavOpen(false);
    } else {
      navigate(href);
      setFocus(target);
      setNavOpen(false);
    }
  };

  useEffect(() => {}, []);

  return (
    <div className="navbar">
      <div className="navbar-item" onClick={() => setNavOpen((prev) => !prev)}>
        <div className="navitem-icon">
          <img src={NavBarIcon1} alt="Menu" />
        </div>
        <div className="item-label">Menu</div>
      </div>

      {Menubar.map((it, idx) => (
        <div
          key={idx}
          className={`navbar-item ${focus === it.title ? "active" : ""}`}
          onClick={() => clickMenu(it.title, it.href)}
        >
          <div className="navitem-icon">
            <img src={it.icon} alt="Results2" />
          </div>
          <div className="item-label">{it.title}</div>
        </div>
      ))}
      {token.length === 0 ? (
        <div
          className={`navbar-item ${focus === "Login" ? "active" : ""}`}
          onClick={() => {
            setFocus("Login");
            setOpen((prev) => !prev);
          }}
        >
          <div className="navitem-icon">
            <img src={NavBarIcon6} alt="Login" />
          </div>
          <div className="item-label">Log in</div>
        </div>
      ) : (
        <div
          className={`navbar-item ${focus === "MyPage" ? "active" : ""}`}
          onClick={() => clickMenu("MyPage", "/mypage")}
        >
          <div className="navitem-icon">
            <img src={NavBarIcon5} alt="MyPage" />
          </div>
          <div className="item-label">MyPage</div>
        </div>
      )}
    </div>
  );
};

export default NavBar;
