import { toDate, toZonedTime, format } from "date-fns-tz";

export default function convertToUserTime(
  serverTime: string | null | undefined
): string {
  try {
    if (serverTime) {
      // 1. 서버 시간을 UTC 기준으로 변환
      const utcDate = toDate(serverTime, { timeZone: "Australia/Sydney" });

      // 2. 사용자 타임존 확인
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      // 3. 사용자 타임존으로 변환
      const userDate = toZonedTime(utcDate, userTimeZone);

      // 4. 변환된 시간 포맷팅
      return format(userDate, "yyyy-MM-dd HH:mm:ss", {
        timeZone: userTimeZone,
      });
    } else {
      return "null"; // 서버 시간이 없을 경우 처리
    }
  } catch (error) {
    console.error(
      "Error in convertToUserTime:",
      error,
      "serverTime:",
      serverTime
    );
    return "Invalid Date";
  }
}
