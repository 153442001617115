// tools
import { Dispatch, SetStateAction, useState } from "react";

// ui
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

interface ModalContent {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const AccountStatus = ({ setOpen }: ModalContent) => {
  const [chgPage, setChgPage] = useState("");

  const [selectedValue, setSelectedValue] = useState("1");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  return (
    <div className="account-status">
      <div className="modal-tit-wrap">
        <h3 className="tit">Account Status</h3>
        <span
          className="btn material-symbols-outlined"
          onClick={() => {
            setOpen(false);
            setChgPage("");
          }}
        >
          close
        </span>
      </div>

      <div className="modal-cont-wrap">
        {chgPage === "" && (
          <div className="status-type-wrap">
            <p className="subtit">I would like to...</p>
            <div
              className="btn style01 md"
              onClick={() => {
                setChgPage("break");
              }}
            >
              Take a break
            </div>
            <div
              className="btn style01 md"
              onClick={() => {
                setChgPage("close");
              }}
            >
              Close My Account
            </div>
          </div>
        )}

        {chgPage === "close" && (
          <div className="break-wrap">
            <p className="tit">Close My Account</p>
            <p className="subtxt">
              Your well-being is important. We're here to support you. Please
              let us know why you're closing your account so we can better
              assist you.
            </p>

            <p className="subtit">Select your reason</p>

            <RadioGroup
              aria-labelledby=""
              defaultValue="1"
              name=""
              sx={{ marginBottom: "24px" }}
              onChange={handleChange}
            >
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="Too many  notifications"
              />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label="Concerns about gambling addiction"
              />
              <FormControlLabel
                value="3"
                control={<Radio />}
                label="Subscribe cancellation process is difficult"
              />
              <FormControlLabel value="4" control={<Radio />} label="Other" />

              {selectedValue === "4" && (
                <TextField
                  id=""
                  multiline
                  rows={3}
                  defaultValue="please specify"
                  sx={{ marginLeft: "32px" }}
                />
              )}
            </RadioGroup>

            <div className="notice-area">
              <p>
                Please note that under the conditions of our lottery licence we
                are required to keep a record of customer details for 7 years.
                These records will be retained for any required audits conducted
                by the Government Regulator in accordance with our licence, and
                will not be used for any other purpose.
              </p>
            </div>
          </div>
        )}

        {chgPage === "break" && (
          <div className="break-wrap">
            <p className="tit">Take a break</p>
            <p className="subtxt">
              Your well-being is important. Take a break, and we'll be here when
              you're ready.
            </p>
            <FormControl fullWidth sx={{ margin: "24px 0 12px" }}>
              <InputLabel id="period">Time Period</InputLabel>
              <Select
                labelId="period"
                id="demo-simple-select"
                value={1}
                label="Time Period"
                // onChange={handleChange}
              >
                <MenuItem value={1}>1Month</MenuItem>
                <MenuItem value={2}>2Months</MenuItem>
                <MenuItem value={3}>3Months</MenuItem>
              </Select>
            </FormControl>
            <p className="subtxt">
              Select the time that feels right for you. You can resume anytime
              during or after the selected period.
            </p>

            <p className="subtit">Select your reason</p>

            <RadioGroup
              aria-labelledby=""
              defaultValue="1"
              name=""
              sx={{ marginBottom: "24px" }}
              onChange={handleChange}
            >
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="I have bee receiving too many emails"
              />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label="Taking a break from lottery purchases"
              />
              <FormControlLabel
                value="3"
                control={<Radio />}
                label="Jackpot amounts are too low"
              />
              <FormControlLabel value="4" control={<Radio />} label="Other" />

              {selectedValue === "4" && (
                <TextField
                  id=""
                  multiline
                  rows={3}
                  defaultValue="please specify"
                  sx={{ marginLeft: "32px" }}
                />
              )}
            </RadioGroup>

            <div className="notice-area">
              <p>During your break, you won't be able to purchase any games.</p>
            </div>
          </div>
        )}
      </div>

      {chgPage === "break" && (
        <div className="modal-bottom-wrap">
          <div className="btn-wrap">
            <div
              className="btn style01 sm"
              onClick={() => {
                setOpen(false);
                setChgPage("");
              }}
            >
              Cancel
            </div>
            <div
              className="btn style01 sm white"
              style={{ padding: "12px 36px" }}
            >
              Confirm
            </div>
          </div>
        </div>
      )}

      {chgPage === "close" && (
        <div className="modal-bottom-wrap">
          <div className="btn-wrap">
            <div
              className="btn style01 sm"
              onClick={() => {
                setOpen(false);
                setChgPage("");
              }}
            >
              Cancel
            </div>
            <div
              className="btn style01 sm white"
              style={{ padding: "12px 36px" }}
            >
              Confirm
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountStatus;
