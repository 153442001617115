// tools
import { useState } from "react";
import User from "../../tools/userInfo";

// components
import Limit from "./limit";
import PersonalInfo from "./personalInfo";
import ChangePassword from "./changePassword";
import PayMethod from "./payMethod";
import WithdrwalMethod from "./withdrawalMethod";
import Delete from "./delete";
import { CustModal } from "../common/commonElements";
import EmailNotification from "./emailNotification";
import SmsNotification from "./smsNotification";
import AppNotification from "./appNotification";
import AccountStatus from "./accountStatus";
import Verify from "./verify";

const Settings = () => {
  const { info } = User();
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState("");

  const handleClickOpen = (type: string) => {
    setContent(type);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const changeView = (type: string) => {
    switch (type) {
      case "personal":
        return <PersonalInfo setOpen={setOpen} />;
        break;

      case "password":
        return <ChangePassword setOpen={setOpen} />;
        break;

      case "paymethod":
        return <PayMethod setOpen={setOpen} />;
        break;

      case "withdrawalmethod":
        return <WithdrwalMethod setOpen={setOpen} />;
        break;

      case "limit":
        return <Limit setOpen={setOpen} />;
        break;

      case "email":
        return <EmailNotification setOpen={setOpen} />;
        break;

      case "sms":
        return <SmsNotification setOpen={setOpen} />;
        break;

      case "app":
        return <AppNotification setOpen={setOpen} />;
        break;

      case "status":
        return <AccountStatus setOpen={setOpen} />;
        break;

      case "verify":
        return <Verify setOpen={setOpen} />;
        break;
    }
  };

  return (
    <div className="setting">
      <div className="item">
        <h4 className="tit">
          <span className="material-symbols-rounded">person</span>Personal
          Information
        </h4>
        <div className="edit-wrap">
          <div
            className="btn style01 md"
            onClick={() => handleClickOpen("personal")}
          >
            Personal Information
          </div>
          <div
            className="btn style01 md"
            onClick={() => handleClickOpen("password")}
          >
            Change Password
          </div>
          <div
            className="btn style01 md"
            onClick={() => handleClickOpen("verify")}
          >
            <span>Verify</span>
            <span
              className="material-symbols-rounded icon text-base text-red-600"
              onClick={() => {}}
            >
              error
            </span>
          </div>
        </div>
      </div>

      <div className="item">
        <h4 className="tit">
          <span className="material-symbols-rounded">
            account_balance_wallet
          </span>
          My Wallet
        </h4>

        {/* 미등록시 버튼에 .unverified 추가 */}
        <div
          className="btn style01 md unverified"
          onClick={() => {
            handleClickOpen("paymethod");
          }}
        >
          Payment Method
          <span className="material-symbols-rounded unverified">error</span>
        </div>
        <div
          className="btn style01 md"
          onClick={() => handleClickOpen("limit")}
        >
          Spend Limit
        </div>
      </div>

      <div className="item">
        <h4 className="tit">
          <span className="material-symbols-rounded">notifications</span>
          Notification
        </h4>

        <div
          className="btn style01 md"
          onClick={() => handleClickOpen("email")}
        >
          Email Option
        </div>

        <div className="btn style01 md" onClick={() => handleClickOpen("sms")}>
          SMS Option
        </div>

        <div className="btn style01 md" onClick={() => handleClickOpen("app")}>
          App Push Notification
        </div>

        {/* <div
          className="btn"
          onClick={() => {
            handleClickOpen("withdrawalmethod");
          }}
        >
          Edit
        </div> */}
      </div>

      {/* <div className="item">
        <h4 className="tit">Weekly Spend Limit</h4>
        <p className="txt">
          Your current weekly spend limit is{" "}
          <span>${info.weeklySpendLimit.toLocaleString("au")}</span>{" "}
          <span>(Remaining: ${info.remaining.toLocaleString("au")} )</span>
        </p>
      </div> */}

      <div className="item">
        <h4 className="tit">
          <span className="material-symbols-rounded">settings</span>Account
          Setting
        </h4>

        <div
          className="btn style01 md"
          onClick={() => {
            handleClickOpen("status");
          }}
        >
          Account Status
        </div>
        <div
          className="btn style01 md"
          onClick={() => (window.location.href = "/email")}
        >
          Contact Us
        </div>
      </div>

      <CustModal open={open} setOpen={setOpen} onClose={handleClose}>
        {changeView(content)}
      </CustModal>
      {/* <Delete /> */}

      <div className="btn style01 sm logout">Logout</div>
    </div>
  );
};

export default Settings;
