// tools
import { useEffect, useState } from "react";
import User from "../tools/userInfo";

// comp
import SynItem from "../components/syndicates/synItem";
import NoData from "../components/common/noData";
import createAxiosInstance from "../api/axiosConfig";
import Swal from "sweetalert2";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { Select } from "@mui/material";
import MySynItem from "../components/syndicates/mySynItem";

// type
export interface Nums {
  idx: number;
  num: string;
}
export interface SyndicateInfo {
  idx: number;
  name: string;
  systemCode: string | null;
  auDrawTime: string;
  syndicateName: string;
  description: string;
  drawnum: number;
  games: number;
  jackpot: number;
  multiplier: string;
  price: string;
  productCode: string;
  ticketIssued: number;
  ticketSold: number;
  nums: Nums[];
  status: string;
  isOwner?: boolean | null;
}

interface SystemMenus {
  code: string;
  title: string;
}

const SystemMenu: SystemMenus[] = [
  { code: "All", title: "All Lottery" },
  { code: "STD", title: "Standard" },
  {
    code: "ST6",
    title: "System 6",
  },
  {
    code: "ST7",
    title: "System 7",
  },
  {
    code: "ST8",
    title: "System 8",
  },
  {
    code: "ST9",
    title: "System 9",
  },
  {
    code: "STX",
    title: "System 10",
  },
  {
    code: "PIC",
    title: "Pick 1 less",
  },
  {
    code: "GUA",
    title: "Guaranteed",
  },
];

const Syndicates = () => {
  // tools
  const { api, token, setToken, setTotalInfo } = User();
  const axiosInstance = createAxiosInstance(api, token);

  // state
  const [data, setData] = useState<SyndicateInfo[]>([]);
  const [owner, setOwner] = useState<SyndicateInfo | null>(null);
  const [remain, setRemain] = useState<SyndicateInfo[]>([]);

  // filter
  const [selectFilter, setSelectFilter] = useState<string>("All");
  const [selectOpiton, setSelectOption] = useState<string>("draw");
  const [selectSort, setSelectSort] = useState<boolean>(true);
  const [drawFilter, setDrawFilter] = useState(false);
  const [prizeFilter, setPrizeFilter] = useState(false);

  // 필터링된 데이터
  const filteredData =
    selectFilter === "All"
      ? remain
      : remain.filter((item) => item.systemCode === selectFilter);

  // 정렬
  const sortedData = [...filteredData].sort((a, b) => {
    if (drawFilter && prizeFilter) {
      // draw time & price 정렬
      if (
        new Date(a.auDrawTime).getTime() === new Date(b.auDrawTime).getTime()
      ) {
        return parseFloat(a.price) - parseFloat(b.price);
      }
      return a.drawnum - b.drawnum;
    } else if (drawFilter) {
      // draw time 정렬
      return (
        new Date(a.auDrawTime).getTime() - new Date(b.auDrawTime).getTime()
      );
    } else if (prizeFilter) {
      // price 정렬
      return parseFloat(a.price) - parseFloat(b.price);
    }
    return 0;
  });

  // 정렬2
  const sortedData2 = [...filteredData].sort((a, b) => {
    if (selectOpiton === "draw") {
      // draw time 정렬
      if (selectSort) {
        return (
          new Date(b.auDrawTime).getTime() - new Date(a.auDrawTime).getTime()
        );
      } else {
        return (
          new Date(a.auDrawTime).getTime() - new Date(b.auDrawTime).getTime()
        );
      }
    } else if (selectOpiton === "prize") {
      // price 정렬
      if (selectSort) {
        return parseFloat(b.price) - parseFloat(a.price);
      } else {
        return parseFloat(a.price) - parseFloat(b.price);
      }
    }
    return 0;
  });

  console.log("data : ", data.length);
  console.log("sort : ", sortedData.length);
  console.log("owner : ", owner);

  const getSynData = () => {
    axiosInstance
      .get(`/product/syndicate`)
      .then((res) => {
        setData(res.data.data);
      })
      .catch((error) => console.error(error));
  };

  const linkToCreate = () => {
    if (owner !== null) {
      return;
    }

    if (!token) {
      Swal.fire({
        icon: "warning",
        title: "Members Only",
        text: "This method is only for Lottery Cluster members",
        confirmButtonColor: "#af7af1",
        cancelButtonColor: "#876aab",
        allowOutsideClick: false,
      });
    } else {
      location.href = "/syndicates/create";
    }
  };

  useEffect(() => {
    getSynData();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      // 본인 생성 찾기
      const ownerData = data.find((item) => item.isOwner === true) || null;
      setOwner(ownerData);

      // 본인 제외 데이터
      const remainingData = data.filter((item) => item !== ownerData);
      setRemain(remainingData);
    }
  }, [data]);

  return (
    <div className="sub syndicate">
      <div className="sub-top-area">
        <h3 className="tit">Syndicates</h3>
        <p className="txt">Play with a group and get more chances to win</p>

        {token.length > 1 && (
          <div
            className={`btn style01 md ${owner !== null && "disabled"}`}
            onClick={() => linkToCreate()}
          >
            {owner !== null ? "Already exists" : "Create Your Syndicate"}
          </div>
        )}
      </div>
      <div className="syn-filter-wrap">
        <div className="tit">
          <span className="material-symbols-rounded">tune</span>
          <p>Filter</p>
        </div>

        <div
          className={`updown filter ${drawFilter && "up"}`}
          onClick={() => {
            setDrawFilter(!drawFilter);
          }}
        >
          <p>Draw</p>
          <span className="material-symbols-rounded">arrow_drop_down</span>
        </div>

        <div
          className={`updown filter ${prizeFilter && "up"}`}
          onClick={() => {
            setPrizeFilter(!prizeFilter);
          }}
        >
          <p>Prize</p>
          <span className="material-symbols-rounded">arrow_drop_down</span>
        </div>

        <div className="flex items-center gap-5">
          <FormControl className="filter">
            <InputLabel id="">System</InputLabel>
            <Select labelId="" id="System" value={selectFilter} label="System">
              {SystemMenu.map((it, idx) => (
                <MenuItem
                  key={idx}
                  value={it.code}
                  onClick={() => setSelectFilter(it.code)}
                >
                  {it.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <FormControl className="filter">
            <InputLabel id="">Option</InputLabel>
            <Select id="option" defaultValue={selectOpiton}>
              <MenuItem value={"draw"} onClick={() => setSelectOption("draw")}>
                Draw
              </MenuItem>
              <MenuItem
                value={"prize"}
                onClick={() => setSelectOption("prize")}
              >
                Prize
              </MenuItem>
            </Select>
          </FormControl>
          <div
            className={`updown filter ${
              selectSort && "up"
            } border p-2 py-3 border-slate-500`}
            onClick={() => {
              setSelectSort(!selectSort);
            }}
          >
            <span className="material-symbols-rounded">arrow_drop_down</span>
          </div> */}
        </div>
      </div>
      <div className="syndicate-wrap">
        {owner && <MySynItem data={owner} getSynData={getSynData} />}
        {sortedData.map((it) => (
          <SynItem key={it.idx} data={it} getSynData={getSynData} />
        ))}
      </div>
      {((data.length === 0 && sortedData2.length === 0) ||
        (owner === null && sortedData2.length === 0)) && (
        // 해당 게임이 1개라도 존재하지 않을 경우
        <NoData />
      )}
    </div>
  );
};

export default Syndicates;
