// img
import { useState } from "react";
import Dollar from "../../img/common/dollar-img.png";
import Bg from "../../img/main/syn-blur-bg.svg";

import bgVideoSynd from "../../img/main/lcmSynd_1m.mp4";
import videoPosterSynd from "../../img/main/lcmSynd_1m.jpg";
import syndLogo from "../../img/main/lca_synd.png";
import { useNavigate } from "react-router-dom";

const SyndicateGame = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="video-background synd">
        <video
          autoPlay
          muted
          loop
          id="background-video"
          poster={videoPosterSynd}
        >
          <source src={bgVideoSynd} type="video/mp4" /> Your browser does not
          support the video tag.
        </video>
        <div className="content-overlay">
          <div className="content-wrap">
            <div className="logo-wrap">
              <img
                className="item-logo"
                src={syndLogo}
                alt="Syndicate, Lottery Cluster"
              />
            </div>
          </div>
          <div className="button" onClick={() => navigate("/syndicates")}>
            PLAY NOW
          </div>
        </div>
      </div>
    </>
  );
};

export default SyndicateGame;
