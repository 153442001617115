// tools
import { useState, useEffect } from "react";
import Lottie from "lottie-react";
import LastWinningResult from "../components/sub/lastWinningResult";
import amountMaker from "../tools/amountMaker";

// img
import StarsMega from "../img/sub/stars-mega.json";
import Mega from "../img/common/mega-ball-logo.png";
import Power from "../img/common/power-ball-logo.png";
import MegaLogo from "../img/common/USA_Mega_lottery.png";
import PowerLogo from "../img/common/USA_Power_lottery.png";

// ui
import dayjs, { Dayjs } from "dayjs";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ResultCard from "../components/result/resultCard";
import FloatingGame from "../components/common/FloatingGame";
import { useLotto } from "../App";
import CountDown from "../components/common/countdown";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import axios from "axios";
import User from "../tools/userInfo";
import { error } from "console";
import createAxiosInstance from "../api/axiosConfig";
import { useLocation, useNavigate } from "react-router-dom";
import convertToUserTime from "../tools/userTimeZone";

// types

export interface ResultData {
  type: string;
  drawnum: number;
  winningNumber: string;
  multiplier: number;
  winners: string;
  prizes: string;
  multiWinners: string;
  multiPrizes: string;
  auDrawTime: string;
  exchange: string;
  videoId: string;
}
interface CustomDatePickerProps {
  label: string;
  value: Dayjs | null;
  onChange: (newValue: Dayjs | null) => void;
}

const Result2 = () => {
  const { api } = User();
  const { lottery } = useLotto();
  const navigate = useNavigate();
  const location = useLocation();

  const tab = location.search.split("=")[1];

  // Stat
  const [menu, setMenu] = useState<string>(tab);
  const [data, setData] = useState<ResultData[]>([]);
  const [startDate, setStartDate] = useState<Dayjs | null>(
    dayjs().subtract(30, "day")
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());
  const [page, setPage] = useState<number>(1);
  const [hasMoreData, setHasMoreData] = useState<boolean>(true);

  // API
  const axiosInstance = createAxiosInstance(api, "");

  const fetchData = async (newPage = 1) => {
    try {
      const sDate = startDate?.format("YYYY-MM-DD");
      const eDate = endDate?.format("YYYY-MM-DD");
      const endpoint = `/result/${menu === "power" ? "pb" : "mm"}`;

      const res = await axiosInstance.get(endpoint, {
        params: {
          sDate,
          eDate,
          perPage: 5,
          page: newPage,
        },
      });

      if (res.data.data.next_page_url === null) {
        setHasMoreData(false);
      }

      setData((prevData) => [...prevData, ...res.data.data.data]);
    } catch (error) {
      console.error(error);
    }
  };

  const loadMoreData = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchData(nextPage);
  };

  useEffect(() => {
    setPage(1);
    setData([]);
    setHasMoreData(true);
    fetchData(1);
  }, [startDate, endDate, menu]);

  const isType = (type: string) => {
    const lotteryType = type === "mega" ? "MM" : "PB";
    return lottery.find((lot) => lot.type === lotteryType);
  };

  useEffect(() => {
    const search = location.search;
    const split = search.replace("?", "").split("&");
    const tab = split[0].split("=")[1];

    if (tab) {
      setMenu(tab);
    } else {
      setMenu("power");
    }
  }, [location.search, tab]);

  const changeSelect = (value: string) => {
    navigate(`/result2?tab=${value}`);
  };

  // date Picker
  const CustomDay = (
    props: PickersDayProps<Dayjs> & { allowedDays: number[] }
  ) => {
    const { day, allowedDays, ...other } = props;

    // 오늘 날짜 체크
    const isToday = day.isSame(dayjs(), "day");
    const isAllowed =
      allowedDays.includes(day.day()) && !props.outsideCurrentMonth;

    // 오늘자 선택 가능 요일 겹치면 활성화
    return (
      <PickersDay
        {...other}
        day={day}
        disabled={!isAllowed}
        sx={{
          ...(isAllowed &&
            !isToday && {
              backgroundColor: "white", // 선택 가능 날짜 배경색
              color: "black", // 텍스트 색상
              "&:hover": {
                backgroundColor: menu === "power" ? "#fe7553" : "#4253ff", // 호버 색상
                color: "#fff",
              },
            }),
        }}
      />
    );
  };

  const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
    label,
    value,
    onChange,
  }) => {
    const allowedDays = menu === "power" ? [0, 2, 4] : [3, 6]; // 일,화,목 / 수,토

    return (
      <DatePicker
        label={label}
        value={value}
        onChange={onChange}
        shouldDisableDate={(date: Dayjs) => {
          const isToday = date.isSame(dayjs(), "day");
          return !allowedDays.includes(date.day()) && !isToday; // 선택 가능한 요일 또는 오늘 날짜일 때만 활성화
        }}
        slots={{
          day: (dayProps) => (
            <CustomDay {...dayProps} allowedDays={allowedDays} />
          ),
        }}
        slotProps={{
          textField: {
            error: false,
          },
        }}
      />
    );
  };

  const reward = amountMaker(
    Number(isType(menu)?.jackpot) * Number(isType(menu)?.exchange)
  );

  return (
    <>
      <div className="sub results">
        {/* Game Top Banner */}
        <div className={`game-top-area ${menu}`}>
          <img
            src={menu === "mega" ? MegaLogo : PowerLogo}
            alt={menu === "mega" ? "USA Mega Lottery" : "USA Power Lottery"}
            className="logo"
          />
          <div className="game-info-area">
            <p className="name">
              {menu === "power" ? "USA Power Lottery" : "USA Mega Lottery"}
            </p>
            <p className="prize">
              <span className="unit">{reward.mark} </span>
              {isType(menu)?.isDrawdate === 0 ? (
                <>
                  <span className="num">{reward.amount}</span>
                  <span className="unit"> {reward.unit}</span>
                </>
              ) : (
                <span className="num">Pending</span>
              )}
            </p>
            <p className="time">
              <CountDown
                date={convertToUserTime(String(isType(menu)?.auOrderTime))}
                pending={Number(isType(menu)?.isDrawdate)}
              />
            </p>
          </div>
        </div>
        {/* Select Option */}
        <div className="subWrap">
          <div className="result-wrap">
            <div className="filter-wrap">
              <div className="filter">
                <h4 className="tit">Sort by Game</h4>
                <FormControl fullWidth>
                  <InputLabel id="sort-game">Select Game</InputLabel>
                  <Select
                    labelId="sort-game"
                    id="Powerball"
                    label="Select Game"
                    defaultValue="power"
                    value={menu}
                    onChange={(e) => {
                      setMenu(e.target.value);
                      changeSelect(e.target.value);
                    }}
                  >
                    <MenuItem value="power">USA Power Lottery</MenuItem>
                    <MenuItem value="mega">USA Mega Lottery</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="filter">
                <h4 className="tit">Search by Date Range</h4>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div className="datepicker-wrap">
                    {/* Start date */}
                    <CustomDatePicker
                      label="Start Date"
                      value={startDate}
                      onChange={setStartDate}
                    />
                    {/* End date */}
                    <CustomDatePicker
                      label="End Date"
                      value={endDate}
                      onChange={setEndDate}
                    />
                  </div>
                </LocalizationProvider>
              </div>
            </div>
            {/* Card Components */}
            <div className="result">
              {data?.map((it, idx) => (
                <ResultCard key={idx} type={menu} data={it} />
              ))}
            </div>

            {/* Load More Button */}
            <div
              className={`btn style01 md load-more ${
                hasMoreData ? "" : "stop"
              }`}
              onClick={() => loadMoreData()}
            >
              {hasMoreData ? "Load more" : "No more items to load"}
            </div>
          </div>
          <FloatingGame type={menu} />
        </div>
      </div>
    </>
  );
};

export default Result2;
