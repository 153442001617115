import ReactDOM from "react-dom/client";
import "./index.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { HelmetProvider } from "react-helmet-async";
import "./../src/styles/lc.scss";
import "./../src/styles/lc-responsive.css";
import "./../src/fonts/font.css";
import routes from "./router/routes";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// mode set
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: "#fff",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fff",
          },
          "&.mui-cofused": {
            color: "#fff",
          },
        },
      },
    },
    // MuiSelect: {
    //   styleOverrides: {
    //     root: {
    //       height: "48px",
    //     },
    //   },
    // },
  },
});

root.render(
  <AnimatePresence mode="sync">
    <HelmetProvider>
      <ThemeProvider theme={darkTheme}>
        <RouterProvider router={routes} />
      </ThemeProvider>
    </HelmetProvider>
  </AnimatePresence>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
