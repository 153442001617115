import { Pagination } from "@mui/material";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import createAxiosInstance from "../api/axiosConfig";
import User from "../tools/userInfo";

// img
import NewsImg from "../img/sub/newsimg.png";
import NewsCard from "../components/news/newsCard";

// type
export interface NewsProps {
  idx: number;
  publishedAt: string;
  source: string;
  subject: string;
  thumbnail: string;
  url: string;
}

const News = () => {
  const { api } = User();
  const axiosInstance = createAxiosInstance(api, "");

  const [news, setNews] = useState<NewsProps[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const fetchNews = (page: number) => {
    axiosInstance
      .get(`/support/news`, {
        params: {
          page: page,
        },
      })
      .then((res) => {
        setNews(res.data.data.data);
        setCurrentPage(res.data.data.current_page);
        setLastPage(res.data.data.last_page);
      });
  };

  useEffect(() => {
    fetchNews(currentPage);
  }, [currentPage]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
  };

  return (
    <div className="sub news">
      <div className="sub-top-area">
        <h3 className="tit">News</h3>
      </div>

      <div className="news-wrap">
        {news?.map((it) => (
          <NewsCard key={it.idx} data={it} />
        ))}
      </div>
      <Pagination
        className="pagination"
        count={lastPage}
        page={currentPage}
        onChange={handlePageChange}
      />
    </div>
  );
};

export default News;
