// tools
import { Dispatch, SetStateAction, useState } from "react";

// ui
import { Checkbox, FormControlLabel, Radio, TextField } from "@mui/material";

// img
import Mastercard from "../../img/sub/mastercard-ico.svg";
import Visa from "../../img/sub/visa-ico.svg";
import PayPal from "../../img/sub/paypal-ico.svg";
import Paysafe from "../../img/sub/paysafe-ico.svg";

interface ModalContent {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const PayMethod = ({ setOpen }: ModalContent) => {
  const methods = ["card", "addCard", "paypal", "paysafe"];
  const [expandedIndex, setExpandedIndex] = useState(0);
  const [picked, setPicked] = useState(false);

  const handleExpand = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? prevIndex : index));
  };

  return (
    <div className="pay-method">
      <div className="modal-tit-wrap">
        <h3 className="tit">Payment Method</h3>
        <span
          className="btn material-symbols-rounded"
          onClick={() => setOpen(false)}
        >
          close
        </span>
      </div>
      <div className="modal-cont-wrap">
        <p className="subtit">Why Not Secure Your Payment?</p>
        <p className="subtxt">
          Securely store your card and enjoy faster checkouts. You can change or
          remove your card information whenever you choose.
        </p>

        <div className="payment-method">
          {/* 등록된 카드가 있을때 */}
          {methods.map((method, index) => {
            return (
              <div
                key={index}
                className={`item  ${expandedIndex === index ? "open" : ""}`}
                onClick={() => handleExpand(index)}
              >
                {/* 등록된 카드 있을 때 */}
                {method === "card" && (
                  <div className="card">
                    <div className="top-area">
                      <Radio
                        value="a"
                        name="radio-buttons"
                        checked={expandedIndex === index}
                        readOnly
                      />
                      <img src={Visa} alt="Visa" className="icon" />
                      <p className="txt">5217 29xx xxxx x084</p>
                      <p className="expired">EXPIRED</p>
                    </div>
                    {expandedIndex === index && (
                      <div className="bottom-area">
                        <div className="card-info">
                          <div className="info">
                            <p className="tit">CARD HOLDER</p>
                            <p className="txt">KIM GU SONG</p>
                          </div>
                          <div className="info">
                            <p className="tit">Expries</p>
                            <p className="txt">11/24</p>
                          </div>
                        </div>
                        <span className="material-symbols-rounded btn">
                          delete
                        </span>
                      </div>
                    )}
                  </div>
                )}

                {/* 신규카드추가 */}
                {method === "addCard" && (
                  <div className="add-card">
                    <div className="top-area">
                      <Radio
                        value="a"
                        name="radio-buttons"
                        checked={expandedIndex === index}
                        readOnly
                      />
                      <span className="material-symbols-rounded icon">
                        add_card
                      </span>
                      <p className="txt">Add New Card</p>
                    </div>
                    {expandedIndex === index && (
                      <div className="bottom-area">
                        <div className="provider-wrap">
                          <div
                            className={`provider ${picked && "picked"}`}
                            onClick={() => {
                              setPicked(!picked);
                            }}
                          >
                            <img src={Visa} alt="Visa" />
                          </div>
                          <div className={`provider ${picked && "picked"}`}>
                            <img src={Mastercard} alt="Mastercard" />
                          </div>
                        </div>
                        <form>
                          <div className="input-wrap">
                            <TextField label="Card Number" fullWidth />
                          </div>

                          <div className="input-wrap">
                            <TextField
                              label="Card Holder Name"
                              placeholder="Name as it appears on your card"
                              fullWidth
                            />
                          </div>

                          <div className="input-wrap">
                            <TextField
                              label="Expiration Date"
                              placeholder="MM/YYYY"
                              fullWidth
                            />

                            <TextField
                              label="CVV"
                              placeholder="123"
                              fullWidth
                            />
                          </div>
                          <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="Securely stroe for futrue use"
                          />
                        </form>
                        <div className="btn style01 md spc">Add a card</div>
                      </div>
                    )}
                  </div>
                )}

                {/* 페이팔 */}
                {method === "paypal" && (
                  <div className="paypal">
                    <div className="top-area">
                      <Radio
                        value="a"
                        name="radio-buttons"
                        checked={expandedIndex === index}
                        readOnly
                      />
                      <img src={PayPal} alt="PayPal" className="icon" />
                      <p className="txt">Add PayPal</p>
                    </div>
                    {expandedIndex === index && (
                      <div className="bottom-area">
                        <div className="btn style01 md">
                          <img
                            src={PayPal}
                            alt="PayPal"
                            style={{ width: "100px" }}
                          />
                        </div>
                        <FormControlLabel
                          control={<Checkbox defaultChecked />}
                          label="Securely stroe for futrue use"
                        />
                      </div>
                    )}
                  </div>
                )}

                {/* 페이세이프티 */}
                {method === "paysafe" && (
                  <div className="paysafe">
                    <div className="top-area">
                      <Radio
                        value="a"
                        name="radio-buttons"
                        checked={expandedIndex === index}
                        readOnly
                      />
                      <img src={Paysafe} alt="Paysafe" className="icon" />
                      <p className="txt">Add Paysafecard</p>
                    </div>
                    {expandedIndex === index && (
                      <div className="bottom-area">
                        <div className="btn style01 md">
                          <img
                            src={Paysafe}
                            alt="Paysafe"
                            style={{ width: "100px" }}
                          />
                        </div>
                        <FormControlLabel
                          control={<Checkbox defaultChecked />}
                          label="Securely stroe for futrue use"
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>

        <div className="notice-area">
          <p>
            Rest assured that your payment details are securely encrypted. You
            can manage, update, or remove your card details anytime in your
            account settings.
          </p>
        </div>
      </div>
      <div className="modal-bottom-wrap">
        <div className="btn-wrap">
          <div className="btn style01 sm" onClick={() => setOpen(false)}>
            Cancel
          </div>
          <div
            className="btn style01 sm white"
            style={{ padding: "12px 36px" }}
          >
            Confirm
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayMethod;
