import axios from "axios";
import React, { useState } from "react";
import { FaBowlingBall } from "react-icons/fa";
import { FaRegEdit } from "react-icons/fa";
import { TiDelete } from "react-icons/ti";
import User from "../../tools/userInfo";
import FavTestCard from "./favTestCard";

interface Nums {
  idx: number;
  num: string;
}

export interface GameInfo {
  idx: number;
  methodCode: string;
  systemCode: string;
  productCode: string;
  ticketQty: number;
  nums: Nums[];
}

interface TestProps {
  it: GameInfo;
  removeFav: (idx: number) => void;
}

const FavTestComp = ({ it, removeFav }: TestProps) => {
  const { api, token } = User();

  return (
    <div key={it.idx} className="border p-2">
      <div className="flex justify-between">
        <div className="flex space-x-5">
          <FaBowlingBall
            className={`mr-5 ${
              it.productCode === "PB" ? "text-red-500" : "text-yellow-400"
            }`}
          />
          <span>키값: {it.idx}</span>
          <span>
            게임타입:&nbsp;
            <span
              className={
                it.methodCode === "SYS" ? "text-blue-200" : "text-green-200"
              }
            >
              {it.methodCode}
            </span>
          </span>
          <span>
            게임코드: <span>{it.systemCode}</span>
          </span>
          <span>
            <span
              className={
                it.productCode === "PB" ? "text-red-500" : "text-yellow-400"
              }
            >
              {it.productCode}
            </span>
          </span>
          <span>갯수: {it.ticketQty}</span>
        </div>
        <div className="space-y-3 w-[500px]">
          {it.nums.map((item: any, idx: number) => (
            <FavTestCard
              key={idx}
              item={item}
              idx={idx}
              type={it.productCode}
              method={it.methodCode}
              system={it.systemCode}
            />
          ))}
        </div>
        <button onClick={() => removeFav(it.idx)} className="px-2 h-6">
          <TiDelete className="text-2xl text-slate-500 " />
        </button>
      </div>
    </div>
  );
};

export default FavTestComp;
