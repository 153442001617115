import { ReactElement, useEffect, useState } from "react";

// ui
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

// tools
import { Ball, BallSet, Nums } from "../types/ball";
import { make1dollar } from "../tools/make1dollar";
import { RandomNumber } from "../tools/random";
import Swal from "sweetalert2";
import User from "../tools/userInfo";
import createAxiosInstance from "../api/axiosConfig";

// comp
import ShowNums from "../components/cart/showNums";
import CreateCard from "../components/syndicates/createCard";
import { formatDate } from "../tools/formatDate";
import { error } from "console";
import { update } from "../tools/zustandUpdate";

// tpye
interface SynGame {
  type: string;
  system: string;
  qty: number;
  date: string;
  multi: false;
  share: number;
  price: number;
  title: string;
  explain: string;
}

interface SystemMenu {
  idx: number;
  name: string;
  code: string;
  description: string;
  regular: number;
  special: number;
  games: number;
}

export interface GameCard {
  id: number;
  wBall: (number | string)[] | number[];
  sBall: number | string;
  method: string;
}

interface FilterWords {
  title: boolean;
  explain: boolean;
}

interface DrawDates {
  auDrawTime: string;
  drawnum: number;
  playdate: string;
}

const SyndicateCreate = () => {
  // Tools
  const { api, token, info, setTotalInfo } = User();
  const axiosInstance = createAxiosInstance(api, token);

  // Setting's
  const [type, setType] = useState<string>("power");
  const [date, setDate] = useState<DrawDates[]>();
  const [multi, setMulti] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);
  const [agree, setAgree] = useState<boolean>(false);

  // System List / select Sys / create 1 $ game / minimum price
  const [system, setSystem] = useState<SystemMenu[]>([]);
  const [selectSystem, setSelectSystem] = useState<string>("STD");
  const [selectDate, setSelectDate] = useState<DrawDates>();
  const [dateIdx, setDateIdx] = useState<number>(0);
  const [minDollar, setMinDollar] = useState<boolean>(false);

  // State's
  // Game count / Words Filter / Title, Explain
  const [qty, setQty] = useState<number>(1);
  const [filtering, setFiltering] = useState<FilterWords>({
    title: false,
    explain: false,
  });
  const [gameText, setGameText] = useState({
    title: "",
    explain: "",
  });

  // Game card
  const [card, setCard] = useState<GameCard[]>([]);

  // User account
  const hasAccount = Number(info.cash) + Number(info.prize);

  /** Select System - price, number set */
  const priceAcc = (system: string) => {
    const price = multi ? 10.45 : 7;
    switch (system) {
      case "STD":
        return price;
      case "GUA":
        return price * 26;
      case "PIC":
        return price * 65;
      case "ST6":
        return price * 6;
      case "ST7":
        return price * 21;
      case "ST8":
        return price * 56;
      case "ST9":
        return price * 126;
      case "STX":
        return price * 252;
    }
  };

  /** 총 가격 */
  const price = Number((Number(priceAcc(selectSystem)) * qty).toFixed(2));

  const [share, setShare] = useState<number>(1);
  const [cost, setCost] = useState(price);

  /** handleShare */
  const handleShareChange = (value: string) => {
    if (/^\d*$/.test(value)) {
      const newShare = Math.max(Math.ceil(Number(value))); // 최소 1명, 소수점 올림
      setShare(newShare);
      setCost(Math.ceil((price / newShare) * 100) / 100); // cost 계산 (둘째 자리 올림)
    }
  };

  //** handleCost */
  const handleCostChange = (value: string) => {
    if (/^\d*(\.\d{0,2})?$/.test(value)) {
      const newCost = Math.ceil(Number(value) * 100) / 100; // 소수점 셋째 자리 올림
      setCost(newCost);
      setShare(Math.ceil(price / newCost)); // share 계산 (올림)
    }
  };

  /** STEP 유효성 검사 */
  const step1 = price > 3000;
  const step2 =
    !minDollar && gameText.title.length > 1 && gameText.explain.length > 1
      ? true
      : false;
  const step3 = card.every((it) => !it.wBall.includes(0) && it.sBall !== 0);

  const hasTrueValue = (obj: Record<string, boolean>): boolean => {
    return Object.values(obj).some((value) => value === true);
  };

  /** STEP disabled Check */
  const isDisabledStep = () => {
    if (step === 0) {
      if (step1 === true) {
        return "disabled";
      } else {
        return "";
      }
    } else if (step === 1) {
      if (step2 === true && !filtering.explain && !filtering.title) {
        return "";
      } else {
        return "disabled";
      }
    } else if (step === 2) {
      if (step3 === true) {
        return "";
      } else {
        return "disabled";
      }
    }
  };

  /** Notice Alert */
  const handleNotice = () => {
    const termsText = `
1. Acceptance of Terms: By accessing our Services, you agree to comply with these Terms and Conditions.\n
2. Eligibility: You must be at least 18 years old to use our Services.\n
3. Registration and Account Responsibilities: You are responsible for maintaining the confidentiality of your account.\n
4. Use of Services: You agree to use our Services only for lawful purposes.\n
5. Fees and Payments: Certain Services may require payment of fees, which are non-refundable.\n
6. User-Generated Content: You retain ownership of your content but grant us a license to use it.\n
7. Intellectual Property: The content of our Services is owned by us and protected by law.\n
8. Third-Party Services and Links: We are not responsible for third-party platforms linked to our Services.\n
9. Privacy Policy: Your use of our Services is governed by our Privacy Policy.\n
10. Disclaimers: The Services are provided "as is" without warranties of any kind.\n
11. Limitation of Liability: We are not liable for indirect, incidental, or consequential damages.\n
12. Indemnification: You agree to indemnify us from claims arising from your use of the Services.\n
13. Governing Law and Dispute Resolution: These Terms are governed by the laws of [Jurisdiction].\n
14. Termination: We may terminate your access to the Services for any violations.\n
15. Entire Agreement: These Terms constitute the entire agreement between you and us.
`;

    Swal.fire({
      icon: "warning",
      title: "Terms and Conditions",
      text: termsText,
      showCancelButton: true,
      confirmButtonText: "Agree",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#af7af1",
      cancelButtonColor: "#876aab",
    }).then((result) => {
      if (result.isConfirmed) {
        // 동의시 액션
        setAgree(true);
        return;
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // 취소시 액션
        return;
      }
    });
  };

  /** STEP MOVE Handler */
  const nextStepHandler = () => {
    if (step === 0) {
      if (qty === 0 || price > 3000) {
        return;
      }
      setStep(1);
    } else if (step === 1) {
      if (step2 === true) {
        setStep(2);
      }
    } else if (step === 2) {
      if (step3 === true) {
        setStep(3);
      }
    } else if (step === 3) {
      if (step3 === true) {
        setStep(4);
      }
    }
  };

  // const checkAccount = () => {
  //   if (hasAccount >= price) {
  //     return;
  //   } else {
  //     return (
  //       <span className="notice">
  //         $ {(hasAccount - Number(price)).toFixed(2)} dollars short.
  //       </span>
  //     );
  //   }
  // };

  const SystemFinder = (code: string) => {
    switch (code) {
      case "STD":
        return "Standard";
      case "ST6":
        return "System6";
      case "ST7":
        return "System7";
      case "ST8":
        return "System8";
      case "ST9":
        return "System9";
      case "STX":
        return "System10";
      case "GUA":
        return "Guaranteed";
      case "PIC":
        return "Pick 1 Less";
    }
  };

  /** Select handler */
  const handleChange = (e: SelectChangeEvent<string>, kind: string) => {
    if (kind === "type") {
      setType(e.target.value);
    } else if (kind === "system") {
      setSelectSystem(String(e.target.value));
    }
  };

  /** 비속어 필터 */
  const bannedWords =
    /fuck|bitch|shit|damn|cunt|bloody|bugger|bollocks|wanker|tosser|prick|arse|twat|dickhead|fag|slut|whore|piss|crap|bastard|git|moron|wuss|sod|knob|bollocks|plonker|minger|shag|skank|tart/i;

  /** Text handler */
  const handleTextChange = (name: string, value: string) => {
    setFiltering((prev) => ({
      ...prev,
      [name]: bannedWords.test(value),
    }));

    setGameText((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  /** Clear Card Nums */
  const handleClear = (target: number) => {
    const updatedCard = card.map((it) => {
      if (it.id === target) {
        const clearWball = it.wBall.map((ball) => (ball === "G" ? "G" : 0));
        const clearSball = it.sBall === "G" ? "G" : 0;
        return { ...it, wBall: clearWball, sBall: clearSball };
      }
      return it;
    });
    setCard(updatedCard);
  };

  /** handle Pick */
  const handlePick = (
    id: number,
    number: number | string,
    isSpecial: boolean
  ) => {
    setCard((prevCards) =>
      prevCards.map((card) => {
        if (card.id !== id) {
          return card;
        }

        if (isSpecial) {
          return {
            ...card,
            method: "M",
            sBall:
              card.sBall === "G" ? "G" : card.sBall === number ? 0 : number,
          };
        } else {
          const updatedWballs = card.wBall.map((ball) =>
            ball === "G" ? "G" : Number(ball)
          );

          const existingIndex = updatedWballs.indexOf(Number(number));
          if (existingIndex !== -1) {
            updatedWballs[existingIndex] = 0;
          } else {
            const zeroIndex = updatedWballs.indexOf(0);
            if (zeroIndex !== -1) {
              updatedWballs[zeroIndex] = Number(number);
            }
          }

          const sortedBalls = updatedWballs
            .filter((ball) => ball !== "G")
            .sort((a, b) => Number(a) - Number(b));
          let finalBalls: (number | string)[] = [];
          let sortedIndex = 0;

          updatedWballs.forEach((ball) => {
            if (ball === "G") {
              finalBalls.push("G");
            } else {
              finalBalls.push(sortedBalls[sortedIndex]);
              sortedIndex++;
            }
          });

          return {
            ...card,
            method: "M",
            wBall: finalBalls,
          };
        }
      })
    );
  };

  /** handleQuick */
  const handleQuick = (id: number) => {
    const generateUniqueNumbers = (
      count: number,
      range: number,
      exclude: number[]
    ) => {
      const uniqueNumbers = new Set<number>(exclude);
      while (uniqueNumbers.size < count + exclude.length) {
        const num = RandomNumber(range);
        if (!uniqueNumbers.has(num)) {
          uniqueNumbers.add(num);
        }
      }
      return Array.from(uniqueNumbers).slice(exclude.length);
    };

    setCard((prevCards) =>
      prevCards.map((card) => {
        if (card.id !== id) {
          return card; // 타겟 X 반환
        }

        const wBallSize =
          selectSystem === "PIC"
            ? 5
            : selectSystem === "ST6"
            ? 6
            : selectSystem === "ST7"
            ? 7
            : selectSystem === "ST8"
            ? 8
            : selectSystem === "ST9"
            ? 9
            : selectSystem === "STX"
            ? 10
            : 5; // 기본 5개 (STD, GUA)

        const wBallRange = BallSet[type].white;
        const sBallRange = BallSet[type].special;

        // wBall과 sBall 상태 확인
        const selectedWballs = card.wBall.filter(
          (ball) => ball !== 0 && ball !== "G"
        ) as number[];
        const isWballsFull = selectedWballs.length === wBallSize;
        const isWballsEmpty = selectedWballs.length === 0;
        const isSballSelected = card.sBall !== 0 && card.sBall !== "G";
        const isSballEmpty = card.sBall === 0 || card.sBall === "G";

        // GUA 처리
        if (selectSystem === "GUA") {
          if (isWballsFull || (isWballsEmpty && isSballEmpty)) {
            const autoPlay = () => {
              const newWballs = generateUniqueNumbers(
                wBallSize,
                wBallRange,
                []
              ).sort((a, b) => a - b);
              return {
                ...card,
                method: "A",
                wBall: newWballs,
                sBall: "G",
              };
            };
            const playQ = setInterval(() => {
              setCard((prevCards) =>
                prevCards.map((card) => {
                  if (card.id === id) {
                    return autoPlay();
                  }
                  return card;
                })
              );
            }, 100);

            setTimeout(() => {
              clearInterval(playQ);
            }, 1000);

            return card;
          } else {
            // 일부 빈 자리만 채우기
            const emptySlots = card.wBall.filter((ball) => ball === 0).length;
            const newNumbers = generateUniqueNumbers(
              emptySlots,
              wBallRange,
              selectedWballs
            );
            const finalWballs = card.wBall.map((ball) =>
              ball === 0 ? newNumbers.shift()! : ball
            );
            return {
              ...card,
              method: "A",
              wBall: finalWballs.sort((a, b) => Number(a) - Number(b)),
              sBall: "G",
            };
          }
        }

        // PIC 처리
        if (selectSystem === "PIC") {
          if (
            card.wBall
              .filter((ball) => ball !== "G")
              .every((ball) => ball !== 0) &&
            card.sBall !== 0
          ) {
            const autoPlay = () => {
              const newWballs = generateUniqueNumbers(4, wBallRange, []).sort(
                (a, b) => a - b
              );
              const newSball = RandomNumber(sBallRange);

              return {
                ...card,
                method: "A",
                wBall: [...newWballs, "G"],
                sBall: newSball,
              };
            };
            const playQ = setInterval(() => {
              setCard((prevCards) =>
                prevCards.map((card) => {
                  if (card.id === id) {
                    return autoPlay();
                  }
                  return card;
                })
              );
            }, 100);

            setTimeout(() => {
              clearInterval(playQ);
            }, 1000);

            return card;
          }
          if (
            card.wBall.every((ball) => ball === 0 || ball === "G") &&
            card.sBall === 0
          ) {
            const autoPlay = () => {
              const newWballs = generateUniqueNumbers(4, wBallRange, []).sort(
                (a, b) => a - b
              );
              const newSball = RandomNumber(sBallRange);

              return {
                ...card,
                method: "A",
                wBall: [...newWballs, "G"],
                sBall: newSball,
              };
            };
            const playQ = setInterval(() => {
              setCard((prevCards) =>
                prevCards.map((card) => {
                  if (card.id === id) {
                    return autoPlay();
                  }
                  return card;
                })
              );
            }, 100);

            setTimeout(() => {
              clearInterval(playQ);
            }, 1000);

            return card;
          }

          const emptySlots = card.wBall.filter((ball) => ball === 0).length;
          if (emptySlots > 0 || card.sBall === 0) {
            const newNumbers = generateUniqueNumbers(
              emptySlots,
              wBallRange,
              selectedWballs
            );

            const finalWballs = card.wBall.map((ball, idx) =>
              ball === 0 && idx !== wBallSize - 1 ? newNumbers.shift()! : ball
            );

            const finalSball =
              card.sBall === 0 ? RandomNumber(sBallRange) : card.sBall;

            return {
              ...card,
              method: "A",
              wBall: finalWballs.sort((a: any, b: any) =>
                a === "G" ? 1 : b === "G" ? -1 : a - b
              ),
              sBall: finalSball,
            };
          }

          return card;
        }

        // 기본 처리
        if (
          (isWballsFull && isSballSelected) ||
          (isWballsEmpty && isSballEmpty)
        ) {
          const autoPlay = () => {
            const requiredCount = wBallSize - (selectSystem === "PIC" ? 1 : 0);

            if (requiredCount <= 0) {
              return card;
            }

            const newWballs = generateUniqueNumbers(
              requiredCount,
              wBallRange,
              []
            ).sort((a, b) => a - b);

            if (newWballs.length < requiredCount) {
              return card;
            }

            const newSball =
              card.sBall === "G" ? "G" : RandomNumber(sBallRange);

            const finalWballs = card.wBall.map((ball, idx) =>
              ball === "G" || (selectSystem === "PIC" && idx === wBallSize - 1)
                ? "G"
                : newWballs.shift() ?? 0
            );

            return {
              ...card,
              method: "A",
              wBall: finalWballs,
              sBall: newSball,
            };
          };

          const playQ = setInterval(() => {
            setCard((prevCards) =>
              prevCards.map((cardItem) => {
                if (cardItem.id === card.id) {
                  return autoPlay();
                }
                return cardItem;
              })
            );
          }, 100);

          setTimeout(() => {
            clearInterval(playQ);
          }, 1000);

          return card;
        }

        const emptySlots = card.wBall.filter((ball) => ball === 0).length;
        const newNumbers = generateUniqueNumbers(
          emptySlots,
          wBallRange,
          selectedWballs
        );
        const finalWballs = card.wBall
          .map((ball, idx) =>
            ball === 0 && !(selectSystem === "PIC" && idx === wBallSize - 1)
              ? newNumbers.shift()!
              : ball
          )
          .sort((a, b) => Number(a) - Number(b));

        const finalSball =
          (typeof card.sBall === "number" && card.sBall === 0) ||
          card.sBall === "G"
            ? card.sBall === "G"
              ? "G"
              : RandomNumber(sBallRange)
            : card.sBall;

        return {
          ...card,
          method: "A",
          wBall: finalWballs,
          sBall: finalSball,
        };
      })
    );
  };

  useEffect(() => {
    if (!token) {
      Swal.fire({
        icon: "warning",
        title: "Members Only",
        text: "This method is only for Lottery Cluster members",
        confirmButtonColor: "#af7af1",
        cancelButtonColor: "#876aab",
        allowOutsideClick: false,
      });
      setTimeout(() => {
        window.location.href = "/";
      }, 1500);
    } else {
      return;
    }
  }, []);

  useEffect(() => {
    const isType = type === "power" ? "pb" : "mm";

    axiosInstance
      .get(`/product/system/${isType}`)
      .then((res) => setSystem(res.data.data));

    axiosInstance.get(`/playdate?type=${isType}`).then((res) => {
      setDate(res.data.data);
      setSelectDate(res.data.data[0]);
    });
  }, [type]);

  /** Pick Random Nums */
  const generateUniqueNumbers = (count: number, range: number): number[] => {
    const uniqueNumbers = new Set<number>();
    while (uniqueNumbers.size < count) {
      uniqueNumbers.add(RandomNumber(range));
    }
    return Array.from(uniqueNumbers);
  };

  // Create Random Card of Systems
  useEffect(() => {
    const generateUniqueCard = (existingCards: GameCard[]) => {
      const uniqueCards: GameCard[] = [];

      while (uniqueCards.length < qty) {
        // 중복되지 wball 배열 생성
        let newWBall: any;
        do {
          newWBall = generateUniqueNumbers(
            selectSystem === "ST6"
              ? 6
              : selectSystem === "ST7"
              ? 7
              : selectSystem === "ST8"
              ? 8
              : selectSystem === "ST9"
              ? 9
              : selectSystem === "STX"
              ? 10
              : 5,
            BallSet[type].white
          ); // 각 시스템 갯수 설정
        } while (
          uniqueCards.some((card) => arraysAreEqual(card.wBall, newWBall))
        ); // 중복된 wBall시 재생성

        // selectSystem별 나머지 정보 설정
        let newCard;
        let idx = uniqueCards.length + 1;

        switch (selectSystem) {
          case "STD":
            newCard = {
              id: idx,
              wBall: newWBall.sort((a: any, b: any) => a - b),
              sBall: RandomNumber(BallSet[type].special),
              method: "A",
            };
            break;
          case "GUA":
            newCard = {
              id: idx,
              wBall: generateUniqueNumbers(5, BallSet[type].white).sort(
                (a, b) => a - b
              ),
              sBall: "G",
              method: "A",
            };
            break;
          case "PIC":
            newCard = {
              id: idx,
              wBall: [
                ...generateUniqueNumbers(4, BallSet[type].white),
                "G",
              ].sort((a: any, b: any) => a - b),
              sBall: RandomNumber(BallSet[type].special),
              method: "A",
            };
            break;
          case "ST6":
          case "ST7":
          case "ST8":
          case "ST9":
          case "STX":
            newCard = {
              id: idx,
              wBall: newWBall.sort((a: any, b: any) => a - b),
              sBall: RandomNumber(BallSet[type].special),
              method: "A",
            };
            break;
          default:
            newCard = {
              id: idx,
              wBall: generateUniqueNumbers(5, BallSet[type].white).sort(
                (a, b) => a - b
              ),
              sBall: RandomNumber(BallSet[type].special),
              method: "A",
            };
        }

        // 새로운 카드 미중복시 추가
        uniqueCards.push(newCard);
      }

      return uniqueCards;
    };

    const updatedCards = generateUniqueCard(card); // 기존 배열 넘겨 중복 방지
    setCard(updatedCards);
  }, [selectSystem, type, qty]);

  /** Equal Wball Array */
  const arraysAreEqual = (
    arr1: (number | string)[],
    arr2: (number | string)[]
  ) => {
    return (
      arr1.length === arr2.length &&
      arr1.every((value, index) => value === arr2[index])
    );
  };

  useEffect(() => {
    if (multi) {
      if (
        Number((price / share).toFixed(1)) < 1 ||
        Number((price / share).toFixed(1)) === Infinity
      ) {
        setMinDollar(true);
      } else {
        setMinDollar(false);
      }
    } else {
      if (price / share < 1 || price / share === Infinity) {
        setMinDollar(true);
      } else {
        setMinDollar(false);
      }
    }
  }, [share, price]);

  useEffect(() => {
    setShare(1);
    setCost(price);
  }, [selectSystem, qty]);

  const changeNameFormat = (tag: string) => {
    switch (tag) {
      case "STD":
        return "Standard";
      case "ST6":
        return "System 6";
      case "ST7":
        return "System 7";
      case "ST8":
        return "System 8";
      case "ST9":
        return "System 9";
      case "STX":
        return "System 10";
      case "GUA":
        return "Guaranteed";
      case "PIC":
        return "Pic 1 less";
    }
  };

  const transformedNums = card.map((it) => ({
    method: it.method,
    num: [...it.wBall.map((it) => it.toString()), it.sBall.toString()],
  }));

  const data = {
    productCode: type === "power" ? "pb" : "mm",
    systemCode: selectSystem === "STD" ? null : selectSystem,
    ticketQty: qty,
    drawnum: selectDate?.drawnum,
    multiplier: multi ? "Y" : "N",
    ticketIssued: share,
    totalAmount: price,
    price: cost,
    title: gameText.title,
    description: gameText.explain,
    nums: transformedNums,
  };

  const createSyndicate = () => {
    if (!agree) {
      return;
    }

    axiosInstance
      .post(`/syndicate`, data)
      .then((res) => {
        console.log(res);
        update(api, token, setTotalInfo);
        nextStepHandler();
      })
      .catch((error) => {
        // 상태 코드 확인 후 메세지 출력
        if (error.response && error.response.status !== 200) {
          const errorMessage = error.response.data?.message || "Error";
          Swal.fire({
            icon: "error",
            text: errorMessage,
            confirmButtonColor: "#e2e2e2",
            didClose: () => {
              window.location.href = "/";
            },
          });
        } else {
          console.log(error);
        }
      });
  };

  return (
    <div className="sub syndicate-create">
      <div className="sub-top-area">
        <h3 className="tit">Syndicates</h3>
        <p className="txt">
          Create your own game to play as a group and increase your chances of
          winning!
        </p>
      </div>

      <div className="create-wrap">
        <div className="stepper-txt-wrap">
          <div className="stepper-wrap">
            <div
              className={`stepper ${
                step === 0 ? "active" : step > 0 ? "complete" : ""
              }`}
            >
              <p>
                <span className="num">01</span>
                <span className="material-symbols-rounded">check</span>
              </p>
              <div className="stepper-bar"></div>
            </div>

            <div
              className={`stepper ${
                step === 1 ? "active" : step > 1 ? "complete" : ""
              }`}
            >
              <p>
                <span className="num">02</span>
                <span className="material-symbols-rounded">check</span>
              </p>
              <div className="stepper-bar"></div>
            </div>

            <div
              className={`stepper ${
                step === 2 ? "active" : step > 2 ? "complete" : ""
              }`}
            >
              <p>
                <span className="num">03</span>
                <span className="material-symbols-rounded">check</span>
              </p>
              <div className="stepper-bar"></div>
            </div>
          </div>

          {step === 0 ? (
            <p>
              Step 1: Create your own game to play as a group and increase your
              chances of winning!
            </p>
          ) : step === 1 ? (
            <p>Step 2: Halfway done. The syndicate is coming to life!</p>
          ) : step === 2 ? (
            <p>Step 3: Last Step. Choose your numbers and you are set to GO!</p>
          ) : (
            ""
          )}
        </div>

        {/* STEP 1 */}
        {step === 0 && (
          <>
            <div className="step step01">
              <div className="item-wrap">
                <div className="item">
                  <h4 className="tit">Select Lottery</h4>
                  <FormControl>
                    <Select
                      value={type}
                      onChange={(e) => handleChange(e, "type")}
                    >
                      <MenuItem value="power">USA Power Lottery</MenuItem>
                      <MenuItem value="mega">USA Mega Lottery</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div className="item">
                  <h4 className="tit">Select a Game Type</h4>
                  <FormControl fullWidth>
                    <Select
                      defaultValue={selectSystem}
                      onChange={(e) => handleChange(e, "system")}
                    >
                      <MenuItem value="STD">Standard</MenuItem>
                      {system.map((it, idx) => (
                        <MenuItem key={idx} value={it.code}>
                          {it.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="item">
                  <h4 className="tit">Select Number of Games</h4>
                  <div className="quantity-control">
                    <div
                      className="btn minus"
                      onClick={() => {
                        if (qty === 1) {
                          return;
                        } else {
                          setQty((prev) => prev - 1);
                        }
                      }}
                    >
                      -
                    </div>
                    <TextField
                      id=""
                      value={qty}
                      sx={{ width: "60px" }}
                      onChange={(e) => setQty(Number(e.target.value))}
                    />
                    <div
                      className="btn plus"
                      onClick={() => setQty((prev) => prev + 1)}
                    >
                      +
                    </div>
                  </div>
                </div>
              </div>

              {/* working here */}
              <div className="item">
                <h4 className="tit">Select Draw Date</h4>
                <FormControl sx={{ width: "50%" }}>
                  <Select value={dateIdx}>
                    {date?.map((it, idx) => (
                      <MenuItem
                        key={idx}
                        value={idx}
                        onClick={() => {
                          setSelectDate(it);
                          setDateIdx(idx);
                        }}
                      >
                        {formatDate(it.playdate)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="item">
                <h4 className="tit">Multiplier</h4>
                <div className="layout">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={multi}
                        onClick={() => setMulti((prev) => !prev)}
                      />
                    }
                    label="Multiplier added"
                  />
                  <div className="txt-wrap">
                    <p className="txt">$ 3.45 per combination</p>
                    <p className="exp">
                      Boost the potential winnings up to 5 times on all
                      non-jackpot prizes!
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="step step02">
              <h4 className="tit">Total</h4>
              <p>
                <span className="num">$ {price}</span>
                {/* {checkAccount()} */}
              </p>

              {/* 신디케이트 $3000초과 경고문구 */}
              {price > 3000 && (
                <p className="notice">
                  Maximum amount per single syndicate is capped at $3,000.
                </p>
              )}
            </div>
          </>
        )}

        {/* STEP2 */}
        {step === 1 && (
          <div className="step step03">
            <div className="item">
              <h4 className="tit">
                1 AUD = $1 Per share, {Math.ceil(price)} shares total.
              </h4>
              <div className="textfield-wrap">
                <TextField
                  value={share}
                  onChange={(e) => handleShareChange(e.target.value)}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                  sx={{
                    width: "120px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                />
                <p>The number of Shares</p>
              </div>
              <div className="textfield-wrap" style={{ marginTop: "12px" }}>
                <TextField
                  value={share === 1 ? price : cost}
                  onChange={(e) => handleCostChange(e.target.value)}
                  inputProps={{
                    inputMode: "decimal",
                    pattern: "[0-9]*[.,]?[0-9]*",
                  }}
                  sx={{
                    width: "120px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                />
                <p>Cost per share</p>
              </div>

              {/* Test */}
              {/* <br />
              <div>
                <p>Total Price: ${price.toFixed(2)}</p>
                <p>Number of Shares: {share}</p>
                <p>Cost per Share: ${cost.toFixed(2)}</p>
              </div> */}

              {minDollar ? (
                <div className="redTxt">
                  A minimum of 1 dollar must be maintained.
                </div>
              ) : (
                <></>
              )}
            </div>

            <div className="item">
              <h4 className="tit">Syndicate Title</h4>
              <TextField
                id="title"
                onChange={(e) => handleTextChange("title", e.target.value)}
                value={gameText.title}
                placeholder="Max  20 characters"
                sx={{ width: "50%" }}
                inputProps={{
                  maxLength: 20,
                }}
              />
              <h4 className="tit" style={{ marginTop: "24px" }}>
                Syndicate Explain
              </h4>

              <TextField
                id="explain"
                onChange={(e) => handleTextChange("explain", e.target.value)}
                value={gameText.explain}
                placeholder="Max 50 characters"
                inputProps={{
                  maxLength: 50,
                }}
                helperText={
                  <div className="helper-txt">
                    <p>
                      Here’s a tip for creating a short, simple description of
                      your syndicate:
                    </p>
                    <ol>
                      <li>
                        Write your description as directly and simply as
                        possible.
                      </li>
                      <li>Use a clear and catchy tone to grab attention.</li>
                      <li>Keep your description within 100 characters.</li>
                      <li>
                        Avoid using inappropriate language to attract users.
                      </li>
                    </ol>
                  </div>
                }
                fullWidth
                multiline
                rows={2}
              />
              {(filtering.title || filtering.explain) && (
                <p className="text-red-500">
                  You cannot use inappropriate language.
                </p>
              )}
            </div>
          </div>
        )}

        {/* STEP3 */}
        {step === 2 && (
          <div className="step step04">
            <div className="ticket-wrap">
              {card.map((it, idx) => (
                <CreateCard
                  key={idx}
                  data={it}
                  idx={idx}
                  type={type}
                  handleClear={handleClear}
                  handlePick={handlePick}
                  handleQuick={handleQuick}
                />
              ))}
            </div>
          </div>
        )}

        {/* STEP4 */}
        {step === 3 && (
          <div className="step step05">
            <div className="info-wrap">
              <h3>Syndicate Summary</h3>
              <div className="item-wrap">
                <div className="item">
                  <h4 className="tit">Lottery</h4>

                  <TextField
                    id=""
                    disabled
                    defaultValue={`${
                      type === "power"
                        ? "USA Power Lottery"
                        : "USA Mega Lottery"
                    }`}
                  />
                </div>
                <div className="item">
                  <h4 className="tit">Type of Game</h4>

                  <TextField
                    id=""
                    disabled
                    defaultValue={SystemFinder(selectSystem)}
                  />
                </div>
                <div className="item">
                  <h4 className="tit">Number of Games</h4>

                  <TextField
                    id=""
                    disabled
                    defaultValue={qty}
                    sx={{ width: "60px" }}
                  />
                </div>
              </div>
              <div className="item-wrap">
                <div className="item">
                  <h4 className="tit">Draw Date</h4>

                  <TextField id="" disabled defaultValue="Sat Nov 09 2024" />
                </div>
                <div className="item">
                  <h4 className="tit">Multiplier</h4>

                  <TextField
                    id=""
                    disabled
                    defaultValue={`${multi ? "O" : "X"}`}
                    sx={{ width: "60px" }}
                  />
                </div>
              </div>
              <div className="item">
                <h4 className="tit">Title</h4>

                <TextField
                  id=""
                  disabled
                  defaultValue={gameText.title}
                  sx={{ width: "50%" }}
                />

                <h4 className="tit" style={{ marginTop: "24px" }}>
                  Explain
                </h4>

                <TextField
                  id=""
                  fullWidth
                  multiline
                  rows={4}
                  disabled
                  defaultValue={gameText.explain}
                />
              </div>

              <div className="item">
                <h4 className="tit">Selected Ball Numbers</h4>
                <div className="show-number-wrap">
                  {card.map((it, idx) => {
                    return (
                      <div key={idx} className="ticket-wrap">
                        <div className="item">
                          <div className="ticket-tit-wrap">
                            <p>Game # {idx + 1}</p>
                          </div>
                          <div className="picked-ball-wrap">
                            <div className="ball-wrap">
                              {it.wBall.map((it, idx) => (
                                <div key={idx} className="ball">
                                  {it}
                                </div>
                              ))}
                              <div className={`ball ${type}`}>{it.sBall}</div>
                            </div>
                          </div>
                          <ShowNums
                            key={idx}
                            nums={String([...it.wBall, it.sBall])}
                            code={type === "power" ? "PB" : "MM"}
                            sysCode={selectSystem}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="item">
                <h4 className="tit">Total</h4>
                <p className="total">$ {price}</p>
              </div>
            </div>

            <div className="agree-wrap">
              <FormControlLabel
                onChange={() => (agree ? setAgree(false) : handleNotice())}
                checked={agree}
                control={<Checkbox />}
                label={
                  <>
                    I agree that the syndicate cannot be deleted or modified
                    after creation
                  </>
                }
              />
            </div>
          </div>
        )}

        {step === 4 && (
          <div className="step step06">
            <div className="syn-summary-wrap">
              <h3>Here is your syndicate!</h3>
              <div className="syn-summary">
                <div className="summary">
                  <p className="tit">Lottery</p>
                  <p className="txt">
                    {data.productCode === "pb"
                      ? "USA Power Lottery"
                      : "USA Mega Lottery"}
                  </p>
                </div>
                <div className="summary">
                  <p className="tit">Type of Game</p>
                  <p className="txt">
                    {changeNameFormat(
                      data.systemCode === null ? "STD" : data.systemCode
                    )}
                  </p>
                </div>
                <div className="summary">
                  <p className="tit">Draw Date</p>
                  <p className="txt">{String(selectDate?.playdate)}</p>
                </div>

                {card.map((it, idx) => (
                  <div className="summary" key={idx}>
                    <p className="tit">Game #{idx + 1}</p>
                    <p className="txt">
                      <div className="ball-wrap">
                        {it.wBall.map((it, idx) => (
                          <div key={idx} className="ball">
                            {it}
                          </div>
                        ))}

                        <div className={`ball ${type}`}>{it.sBall}</div>
                      </div>
                    </p>
                  </div>
                ))}

                <div className="summary">
                  <p className="tit">Shares</p>
                  <p className="txt">
                    $ {cost} for {share} shares
                  </p>
                </div>
                <div className="summary total">
                  <p className="tit">Total</p>
                  <p className="txt">$ {price}</p>
                </div>
              </div>

              <div
                className="btn style01 md"
                onClick={() => {
                  window.location.replace("/syndicates");
                }}
              >
                Syndicate
              </div>
            </div>
          </div>
        )}

        <div className="btn-wrap prev-next">
          {step !== 0 && step !== 4 && (
            <div
              className="btn style01 md prev"
              onClick={() => {
                setStep(step - 1);
              }}
            >
              Prev
            </div>
          )}
          {step !== 3 && step !== 4 && (
            <div
              className={`btn style01 md spc next ${isDisabledStep()} ${
                qty === 0 && "disabled"
              }`}
              onClick={() => nextStepHandler()}
            >
              Next Step
            </div>
          )}

          {step === 3 && (
            <div
              className={`btn style01 md spc ${!agree && "disabled"}`}
              onClick={() => {
                createSyndicate();
              }}
            >
              Create Syndicate
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SyndicateCreate;
