// tools
import { useState, Dispatch, SetStateAction } from "react";
import NumberOnly from "../../tools/numberOnly";
import User from "../../tools/userInfo";
import Swal from "sweetalert2";

// ui
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import { OutlinedInput } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import createAxiosInstance from "../../api/axiosConfig";
import { error } from "console";

interface ModalContent {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

interface Info {
  [key: string | number]: string | number | null | undefined;
  address?: string;
  birth?: string;
  cash?: number;
  city?: string;
  country?: string;
  createdAt?: string;
  email?: string;
  firstName?: string;
  gender?: string;
  lastName?: string;
  level?: number;
  marketing?: string;
  notify?: string;
  number?: null | string | number;
  phone?: string;
  unit?: null | string;
  cart?: number;
}

const PersonalInfo = ({ setOpen }: ModalContent) => {
  const { api, token } = User();
  const axiosInstance = createAxiosInstance(api, token);

  const [info, setInfo] = useState<Info>(User().info);
  const [checkPass, setCheckPass] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleData = (key: string, value: string) => {
    if (key === "number" || key === "phone") {
      setInfo((prev) => ({ ...prev, [key]: NumberOnly(value) }));
    } else {
      setInfo((prev) => ({ ...prev, [key]: value }));
    }
  };

  const original = User().info;

  const notificationAxios = () => {
    const originalValues = Object.values(original);
    const infoValues = Object.values(info);
    const keys = Object.keys(info);

    const indexs = originalValues
      .map((item, index) => {
        if (!infoValues.includes(item)) return index;
        else return -1;
      })
      .filter((idx) => idx !== -1);

    const revised: Info = {};

    for (let i = 0; i < indexs.length; i++) {
      const index = indexs[i];
      revised[keys[index]] = infoValues[index];
    }

    const dataKey = Object.keys(revised);

    const emptyFirst = dataKey.includes("firstName") && !revised.firstName;
    const emptyLast = dataKey.includes("lastName") && !revised.lastName;
    const emptyPhone = dataKey.includes("phone") && !revised.phone;

    if (emptyFirst || emptyLast || emptyPhone) {
      Swal.fire({
        title: "Error",
        text: "There is an empty value on a required field.",
        icon: "error",
        allowOutsideClick: false,
      });
      setOpen(false);
    } else {
      axiosInstance
        .put(`/auth/update`, revised)
        .then(({ data }) => {
          if (data.message) {
            Swal.fire({
              title: "Update Completed",
              icon: "success",
              allowOutsideClick: false,
              didClose: () => location.reload(),
            });
            setOpen(false);
          }
        })
        .catch(({ response }) => {
          Swal.fire({
            title: "Error",
            text: response.data.message,
            icon: "error",
            allowOutsideClick: false,
          });
          setOpen(false);
        });
    }
  };

  const checkPassAxios = () => {
    const data = {
      password: password,
    };

    axiosInstance
      .post("/auth/verify/password", data)
      .then((res) => {
        if (res.data.message === "success") {
          setCheckPass((prev) => !prev);
          return;
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: error.response.data?.message || "Error",
          timer: 1000,
        });
      });
  };

  return (
    <div className="personal-info">
      <div className="modal-tit-wrap">
        <h3 className="tit">Personal Information</h3>
        <span
          className="btn material-symbols-rounded"
          onClick={() => setOpen(false)}
        >
          close
        </span>
      </div>

      <div className="modal-cont-wrap">
        <form>
          {/* 패스워드 확인 영역 */}
          {!checkPass ? (
            <div className="password-wrap">
              <h4 className="subtit">
                Please re-enter your password <br />
                to update your personal information
              </h4>
              <div className="input-wrap">
                <FormControl sx={{ margin: "12px auto" }}>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <OutlinedInput
                    value={password}
                    onChange={({ target }) => setPassword(target.value)}
                    id="password"
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        checkPassAxios();
                      }
                    }}
                    name="pw"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <span className="material-symbols-rounded">
                            visibility
                          </span>
                        ) : (
                          <span className="material-symbols-rounded">
                            visibility_off
                          </span>
                        )}
                      </IconButton>
                    }
                    label="Password"
                  />
                </FormControl>
              </div>
              <div className="notice-area">
                <p>
                  Any inquireies or requests, kindly navigate to the{" "}
                  <div
                    className="btn style02"
                    onClick={() => {
                      window.location.href = "/email";
                    }}
                  >
                    <span>Contact Us</span>
                  </div>{" "}
                  page for our assistance.
                </p>
              </div>
            </div>
          ) : (
            <>
              <div className="notice-area">
                <p>
                  Your personal information must be updated whenever changes
                  occur to ensure uninterrupted access to our services. Once
                  updated, we'll send you a confirmation email for your records.
                </p>
              </div>
              <div className="input-wrap">
                <TextField
                  label="First Name"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  value={info.firstName}
                  onChange={({ target }) =>
                    handleData("firstName", target.value)
                  }
                />
                <TextField
                  label="Middle Name"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  value={info.lastName}
                  onChange={({ target }) =>
                    handleData("lastName", target.value)
                  }
                />
              </div>

              <div className="input-wrap">
                <TextField
                  label="Surnname"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  value={info.lastName}
                  onChange={({ target }) =>
                    handleData("lastName", target.value)
                  }
                />
              </div>

              <div className="input-wrap">
                <TextField
                  label="Email"
                  fullWidth
                  value="dd"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>

              <div className="input-wrap">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    slotProps={{ textField: { fullWidth: true } }}
                    value={dayjs(info.birth)}
                    onChange={(e: Dayjs | null) => {
                      if (e) handleData("birth", e.format("YYYYMMDD"));
                    }}
                  />
                </LocalizationProvider>
              </div>

              <div className="input-wrap">
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={({ target }) => handleData("gender", target.value)}
                >
                  <FormControlLabel
                    value="MALE"
                    control={<Radio checked={info.gender === "male"} />}
                    label="MALE"
                  />
                  <FormControlLabel
                    value="FEMALE"
                    control={<Radio checked={info.gender === "female"} />}
                    label="FEMALE"
                  />
                  <FormControlLabel
                    value="OTHER"
                    control={<Radio checked={info.gender === "OTHER"} />}
                    label="OTHER"
                  />
                </RadioGroup>
              </div>

              <div className="input-label-wrap">
                <label htmlFor="">Address</label>
                <div className="input-wrap">
                  <TextField
                    label="Unit/Flat"
                    fullWidth
                    value={info.unit ? info.unit : ""}
                    onChange={({ target }) => handleData("unit", target.value)}
                  />

                  <TextField
                    label="Street Number"
                    fullWidth
                    value={info.number ? info.number : ""}
                    onChange={({ target }) =>
                      handleData("number", target.value)
                    }
                  />
                </div>

                <div className="input-wrap">
                  <TextField
                    label="Street Name"
                    fullWidth
                    value={info.address}
                    onChange={({ target }) =>
                      handleData("address", target.value)
                    }
                  />
                </div>

                <div className="input-wrap">
                  <TextField
                    label="City / Suburb"
                    fullWidth
                    value={info.city}
                    onChange={({ target }) => handleData("city", target.value)}
                  />
                </div>
                <div className="input-wrap">
                  <TextField label="Post Code" fullWidth value="12345" />
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Select Country
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={info.country}
                      label="Select Country"
                      onChange={({ target }) =>
                        handleData("country", target.value)
                      }
                    >
                      <MenuItem value="AU">Australia</MenuItem>
                      <MenuItem value="NZ">New Zealand</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div className="input-wrap">
                  <TextField
                    label="Phone Number"
                    fullWidth
                    value={info.phone}
                    onChange={({ target }) => handleData("phone", target.value)}
                  />
                </div>
              </div>
            </>
          )}
        </form>
      </div>
      <div className="modal-bottom-wrap">
        <div className="btn-wrap">
          <div className="btn style01 sm" onClick={() => setOpen(false)}>
            Cancel
          </div>
          {!checkPass ? (
            <div
              className="btn style01 sm white"
              style={{ padding: "12px 36px" }}
              onClick={() => checkPassAxios()}
            >
              Confirm
            </div>
          ) : (
            <div
              className="btn style01 sm white"
              style={{ padding: "12px 36px" }}
              onClick={() => notificationAxios()}
            >
              Save
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
