import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FavData } from "../../pages/favourites";
import { RandomNumber } from "../../tools/random";

interface TicketNums {
  idx: number;
  method: string;
  num: string;
}

interface Ball {
  white: number;
  special: number;
}
interface BallSet {
  [key: string]: Ball;
  mega: Ball;
  power: Ball;
}

// constant
const BallSet: BallSet = {
  mega: {
    white: 70,
    special: 25,
  },
  power: {
    white: 69,
    special: 26,
  },
};

interface FavTicketProps {
  idx: number;
  type: string;
  target: number;
  ticketNums: TicketNums;
  cardData: FavData;
  delTicket: (targetIdx: number, cardIdx: number, code: string) => void;
  setIsSaved: (targetIdx: number) => void;
  setFavList: Dispatch<SetStateAction<FavData[] | undefined>>;
  handleEditAction: (action: Function) => void;
}

const FavTicket = ({
  idx,
  type,
  target,
  ticketNums,
  cardData,
  delTicket,
  setIsSaved,
  setFavList,
  handleEditAction,
}: FavTicketProps) => {
  // const
  const nums = ticketNums.num.split(",");
  const white = nums.slice(0, nums.length - 1);
  const special = nums.pop();

  // stat
  const [ballEditOpen, setBallEditOpen] = useState(false);
  const [wBall, setWball] = useState(white);
  const [sBall, setSball] = useState(special);
  const [isQuick, setIsQuick] = useState<boolean>(false);

  const updateBall = [...wBall, sBall].join(",");

  // func

  // Change Method
  const setMethod = (targetIdx: number, method: string) => {
    setFavList((prevFavList) => {
      if (!prevFavList) return prevFavList;
      return prevFavList.map((card) => {
        if (card.idx === cardData.idx) {
          return {
            ...card,
            nums: card.nums.map((numEntry) =>
              numEntry.idx === targetIdx
                ? { ...numEntry, method: method }
                : numEntry
            ),
          };
        }
        return card;
      });
    });
  };

  const quickPlay = (
    target: number,
    wBall: string[],
    sBall: string | undefined,
    systemcode: string
  ) => {
    if (isQuick) {
      return;
    }

    handleEditAction(() => {
      const allWballSelected = wBall.every((it) => it !== "0");
      const noWballSelected = wBall.every((it) => it === "0");

      const sBallSelected =
        sBall !== "0" && sBall !== undefined && sBall !== "G";
      const sBallNotSelected =
        sBall === "0" || sBall === undefined || sBall === "G";

      const allSelected = allWballSelected && sBallSelected;
      const noSelected = noWballSelected && sBallNotSelected;

      const generateUniqueNumber = (
        existingBalls: Set<string>,
        max: number
      ) => {
        let newNum;
        do {
          newNum = String(RandomNumber(max));
        } while (existingBalls.has(newNum));
        existingBalls.add(newNum);
        return newNum;
      };

      const fillEmptySlots = (
        existingWball: string[],
        maxWballCount: number
      ) => {
        const usedNumbers = new Set<string>(
          existingWball.filter((ball) => ball !== "0" && ball !== "G")
        );

        let newWball = existingWball
          .map((ball) =>
            ball === "0"
              ? generateUniqueNumber(usedNumbers, BallSet[type].white)
              : ball
          )
          .sort((a, b) => Number(a) - Number(b));

        return newWball;
      };

      if (systemcode === "PIC") {
        const isEmptyPic = wBall.slice(0, 4).every((it) => it === "0");

        if (!isEmptyPic && !allSelected) {
          const newWball = fillEmptySlots(wBall, 4);
          if (!newWball.includes("G")) {
            newWball.push("G");
          }

          const newSball =
            sBall === "0" || sBall === undefined
              ? String(RandomNumber(BallSet[type].special))
              : sBall;

          setWball(newWball);
          setSball(newSball);
          setIsSaved(target);
          setMethod(ticketNums.idx, "A");
        } else {
          const intervalId = setInterval(() => {
            const usedNumbers = new Set<string>();
            const newWball = Array(4)
              .fill(null)
              .map(() => generateUniqueNumber(usedNumbers, BallSet[type].white))
              .sort((a, b) => Number(a) - Number(b));

            newWball.push("G");

            const newSball = String(RandomNumber(BallSet[type].special));

            setWball(newWball);
            setSball(newSball);
            setIsSaved(target);
            setMethod(ticketNums.idx, "A");
            setIsQuick(true);
          }, 100);

          setTimeout(() => {
            clearInterval(intervalId);
            setIsQuick(false);
          }, 1000);
        }
      } else if (systemcode === "GUA") {
        if (noWballSelected || allWballSelected) {
          const intervalId = setInterval(() => {
            const usedNumbers = new Set<string>();
            const newWball = Array(5)
              .fill(null)
              .map(() => generateUniqueNumber(usedNumbers, BallSet[type].white))
              .sort((a, b) => Number(a) - Number(b));

            const newSball = "G";

            setWball(newWball);
            setSball(newSball);
            setIsSaved(target);
            setMethod(ticketNums.idx, "A");
            setIsQuick(true);
          }, 100);

          setTimeout(() => {
            clearInterval(intervalId);
            setIsQuick(false);
          }, 1000);
        } else {
          const newWball = fillEmptySlots(wBall, 5);

          const newSball = "G";

          setWball(newWball);
          setSball(newSball);
          setIsSaved(target);
          setMethod(ticketNums.idx, "A");
        }
      } else {
        if (!noSelected && !allSelected) {
          const usedNumbers = new Set<string>(
            wBall.filter((ball) => ball !== "0")
          );
          const newWball = wBall
            .map((ball) =>
              ball === "0"
                ? generateUniqueNumber(usedNumbers, BallSet[type].white)
                : ball
            )
            .sort((a, b) => Number(a) - Number(b));

          const newSball =
            sBall === "0" || sBall === undefined
              ? String(RandomNumber(BallSet[type].special))
              : sBall;

          setWball(newWball);
          setSball(newSball);
          setIsSaved(target);
          setMethod(ticketNums.idx, "A");
        } else {
          const intervalId = setInterval(() => {
            const usedNumbers = new Set<string>();
            const newWball = wBall
              .map(() => generateUniqueNumber(usedNumbers, BallSet[type].white))
              .sort((a, b) => Number(a) - Number(b));

            const newSball = String(RandomNumber(BallSet[type].special));

            setWball(newWball);
            setSball(newSball);
            setIsSaved(target);
            setMethod(ticketNums.idx, "A");
            setIsQuick(true);
          }, 100);

          setTimeout(() => {
            clearInterval(intervalId);
            setIsQuick(false);
          }, 1000);
        }
      }
    });
  };

  /** Clear Ticket */
  const clearCard = (target: number) => {
    if (isQuick) {
      return;
    }

    handleEditAction(() => {
      setIsSaved(target);
      const clearWball = wBall.map((it) => (it === "G" ? it : "0"));
      setWball(clearWball);

      if (sBall === "G") {
        return;
      } else {
        setSball("0");
      }
    });
  };

  const updateCardData = () => {
    setFavList((prevFavList) => {
      if (!prevFavList) return prevFavList;

      return prevFavList.map((card) => {
        if (card.idx === cardData.idx) {
          return {
            ...card,
            nums: card.nums.map((numEntry) =>
              numEntry.idx === ticketNums.idx
                ? { ...numEntry, num: updateBall }
                : numEntry
            ),
          };
        }
        return card;
      });
    });
  };

  const handlePick = (selectBall: string, isSpecial: boolean) => {
    handleEditAction(() => {
      if (isSpecial) {
        setSball(special === String(selectBall) ? "0" : String(selectBall));
        if (sBall !== String(selectBall)) {
          setIsSaved(cardData.idx);
          setMethod(ticketNums.idx, "M");
        }
      } else {
        if (wBall.includes(String(selectBall))) {
          setWball(
            wBall
              .map((ball) => (ball === selectBall ? "0" : ball))
              .sort((a, b) => Number(a) - Number(b))
          );
          setIsSaved(cardData.idx);
          setMethod(ticketNums.idx, "M");
        } else if (wBall.filter((ball) => ball !== "0").length < wBall.length) {
          const nextEmptyIdx = wBall.findIndex((ball) => ball === "0");
          if (nextEmptyIdx !== -1) {
            const updatedBalls = [...wBall];
            updatedBalls[nextEmptyIdx] = selectBall;
            setWball(updatedBalls.sort((a, b) => Number(a) - Number(b)));
          }
        }
      }
    });
  };

  // useEffect
  useEffect(() => {
    updateCardData();
  }, [updateBall]);

  const ballMaker = () => {
    return Array.from({ length: type === "mega" ? 70 : 69 }, (_, i) => {
      const ballNum = i + 1;
      return (
        <div
          key={ballNum}
          onClick={() => {
            handlePick(String(ballNum), false);
          }}
          className={`ball ${wBall.includes(String(ballNum)) && "picked"}`}
        >
          {ballNum}
        </div>
      );
    });
  };

  const specialMaker = () => {
    return Array.from({ length: type === "mega" ? 25 : 26 }, (_, i) => {
      const ballNum = i + 1;
      return (
        <div
          key={ballNum}
          onClick={() => handlePick(String(ballNum), true)}
          className={`ball ${sBall === String(ballNum) && "picked"}`}
        >
          {ballNum}
        </div>
      );
    });
  };

  return (
    <div className="item">
      <div className="ticket-tit-wrap">
        <p>Game #{idx + 1}</p>
        <div className="btn-wrap">
          <span
            className={`btn material-symbols-rounded ${isQuick && "disabled"}`}
            onClick={() =>
              quickPlay(cardData.idx, wBall, sBall, cardData.systemCode)
            }
          >
            bolt
          </span>
          <span
            className={`btn material-symbols-rounded ${isQuick && "disabled"}`}
            onClick={() => clearCard(cardData.idx)}
          >
            mop
          </span>
          {cardData.nums.length !== 1 && (
            <span
              className={`btn material-symbols-rounded ${
                isQuick && "disabled"
              }`}
              onClick={() =>
                delTicket(target, cardData.idx, cardData.systemCode)
              }
            >
              delete
            </span>
          )}
        </div>
      </div>

      <div className="picked-ball-wrap">
        <div
          className="ball-wrap"
          onClick={() => setBallEditOpen(!ballEditOpen)}
        >
          {wBall.map((it, idx) => (
            <div key={idx} className="ball">
              {it !== "0" && it}
            </div>
          ))}

          <div className={`ball ${type === "mega" ? "mega" : "power"}`}>
            {sBall !== "0" && sBall}
          </div>
          <span
            className="material-symbols-rounded"
            onClick={() => {
              setBallEditOpen(true);
            }}
          >
            keyboard_arrow_down
          </span>
        </div>

        <div className={`vertical-trans ${ballEditOpen ? "open" : "close"}`}>
          <div className="edit-ball-wrap">
            <div className="ball-count">
              <div className="tit-count-wrap">
                <p className="tit">Ball</p>
                <p className="count">
                  <span>{wBall.filter((it) => it !== "0").length}</span> of
                  &nbsp;
                  {wBall.length}
                </p>
              </div>
            </div>

            <div className="ball-wrap">{ballMaker()}</div>

            <div className="tit-count-wrap" style={{ marginTop: "12px" }}>
              <p className="mega">Mega ball</p>
              <p className="count">
                <span>{special !== "0" ? "1" : "0"}</span> of 1
              </p>
            </div>

            {special !== "G" && (
              <div
                className={`ball-wrap ${type === "mega" ? "mega" : "power"}`}
              >
                {specialMaker()}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FavTicket;
