import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLotto } from "../../App";
import { MenuItem } from "./sideBar";

interface SideBarItemProps {
  data: MenuItem;
  activeTab: string | null;
}

const SideBarItem = ({ data, activeTab }: SideBarItemProps) => {
  const { navOpen, setNavOpen } = useLotto();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(true);

  useEffect(() => {
    const savedState = localStorage.getItem(`menuState-${data.title}`);
    if (savedState) {
      setIsOpen(savedState === "true");
    }
  }, [data.title]);

  const handleClick = () => {
    if (data.href) {
      navigate(`${data.href}`);
    } else {
      const newOpenState = !isOpen;
      setIsOpen(newOpenState);

      localStorage.setItem(`menuState-${data.title}`, newOpenState.toString());
    }
  };

  const isCurrnet = (href: string) => {
    const currentPath = location.pathname;
    const currentSearchParams = new URLSearchParams(location.search);
    const hrefParams = new URLSearchParams(href.split("?")[1]);

    return (
      currentPath === href.split("?")[0] &&
      currentSearchParams.toString() === hrefParams.toString()
    );
  };

  const isAnySubItemActive = data.sub?.some((it) => isCurrnet(it.href));

  return (
    <div
      className={`group ${
        activeTab === data.href || isAnySubItemActive ? "current" : ""
      }`}
    >
      <div className={`item ${data.title}`} onClick={handleClick}>
        <p>
          <img src={data.img} alt={data.title} />
          {data.title}
          {data.sub && (
            <span
              className={`material-symbols-rounded ${isOpen ? "open" : ""}`}
            >
              keyboard_arrow_down
            </span>
          )}
        </p>
      </div>

      {data.sub && (
        <div className={`vertical-trans ${isOpen ? "open" : "close"}`}>
          <div className="sub-item-wrap">
            {data.sub.map((it, idx) => (
              <div
                className={`sub-item ${isCurrnet(it.href) ? "current" : ""}`}
                onClick={() => {
                  navigate(`${it.href}`);
                  setNavOpen(false);
                }}
                key={idx}
              >
                {it.title}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SideBarItem;
